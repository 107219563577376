import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import Dashboard from '../pages/Dashboard.vue';
import Aggregator from '../pages/Aggregator.vue';
import CSVDownload from '../pages/CSVDownload.vue';
import BadRequest from '../pages/400.vue';
import Forbidden from '../pages/403.vue';
import NotFound from '../pages/404.vue';
import Conflict from '../pages/409.vue';
import ServerError from '../pages/500.vue';
import { AUTH_PATH } from './constants';
import { setFallbackLocale, getUserLang } from '../i18n.js';

Vue.use(VueRouter);

export const router = new VueRouter({

  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/auth-handler',
      name: 'AuthHandler',
      beforeEnter: ((to, from, next) => {
        const { hash } = to;
        const separators = ['#', '=', '&'];
        const parts = hash.split(new RegExp(separators.join('|'), 'g'));
        const position = parts.indexOf('access_token');
        const token = parts[position + 1];
        window.localStorage.setItem('access_token', token);
        if (!window.localStorage.getItem('userinfo') || !store.state.user.user_lang) {
          getUserLang();
          setFallbackLocale();
        }
        next({
          path: window.localStorage.getItem('path') || '/',
        });
      }),
    },
    {
      path: '/',
      name: 'Home',
    },
    {
      path: '/client/:clientId',
      component: Dashboard,
      name: 'Dashboard',
    },
    {
      path: '/aggregator/:aggregatorId',
      component: Aggregator,
      name: 'Aggregator',
      props: true,
    },
    {
      path: '/csv/:csvId',
      component: CSVDownload,
      name: 'CSVDownload',
    },
    {
      path: '/400',
      component: BadRequest,
      name: 'BadRequest',
    },
    {
      path: '/403',
      component: Forbidden,
      name: 'Forbidden',
    },
    {
      path: '/404',
      component: NotFound,
      name: 'NotFound',
    },
    {
      path: '/409',
      component: Conflict,
      name: 'Conflict',
    },
    {
      path: '/500',
      component: ServerError,
      name: 'ServerError',
    },

    // default route, when none of the above matches:
    {
      path: '*',
      component: NotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const access_token = window.localStorage.getItem('access_token');
  if (!access_token) {
    if (to.name !== 'AuthHandler') {
      // At the beginning before settings json is requested, in the absence of auth path avoid /null path to be saved into local storage
      if (to.path !== '/null') {
        window.localStorage.setItem('path', to.path);
      }
      location.href = AUTH_PATH;
      return;
    }
  }
  next();
});
