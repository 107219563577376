import moment from 'moment';

export default {
  filters: {
    moment(date) {
      if (!date) return '-';
      const m = moment.utc(date).toDate();
      return moment(m).local().format('LLL');
    },
  },
};
