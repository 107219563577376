<template>
  <b-container class="item">
    <b-row>
      <b-col lg="6">
        <div v-if="item.link">
          <gamelink
            v-for="(link, index) in gamelinks"
            :key="index"
            :gamelink="link"
          ></gamelink>
        </div>
        <div>
          <p>{{ $t('dashboard.recruitment_list_tab.full.games') }}</p>
          <div v-if="item.video_members">
            <img
              v-if="this.user_lang == 'hu'"
              class="video-icon"
              :id="`video-member`"
              :src="'/img/video_icon_name_hu.png'"
            >
            <img
              v-else-if="this.user_lang == 'fr'"
              class="video-icon"
              :id="`video-member`"
              :src="'/img/video_icon_name_fr.png'"
            >
            <img
              v-else
              class="video-icon"
              :id="`video-member`"
              :src="'/img/video_icon_name_en.png'"
            >
          </div>
          <div>
            <game-image
              v-for="game in item.games"
              :key="`modal-${item.id}-${game.id}`"
              :imageFileName="setGameIcon(game.identifier)"
              :gameName="game.name"
              :identifier="game.identifier"
              :detailed="true"
            ></game-image>
          </div>
        </div>
        <div v-if="item.start_time && !item.end_time" class="duration">
          <p>
            {{ $t('dashboard.recruitment_list_tab.full.active_since') }}
          </p>
          <p class="blue-text">
            {{ $options.filters.moment(item.start_time) }}
          </p>
        </div>
        <div v-else-if="item.start_time && item.end_time" class="duration">
          <p>
            {{ $t('dashboard.recruitment_list_tab.full.active_between') }}
          </p>
          <p class="blue-text">
            {{ $options.filters.moment(item.start_time) }}
          </p>
          <p class="blue-text">
            {{ $options.filters.moment(item.end_time) }}
          </p>
        </div>
        <div v-else-if="!item.start_time && item.end_time" class="duration">
          <p>
            {{ $t('dashboard.recruitment_list_tab.full.active_to') }}
          </p>
          <p class="blue-text">
            {{ $options.filters.moment(item.end_time) }}
          </p>
        </div>
        <div class="open-close">
          <div class="header-column project-open">
            {{ $t('dashboard.recruitment_list_tab.full.open_close') }}
          </div>
          <b-form-checkbox
            v-model="open"
            name="check-button"
            switch
            size="lg"
            button-variant="success"
            @change="openCloseAssessment"
          >
          </b-form-checkbox>
        </div>
        <b-row align-h="center">
          <b-col cols="10" sm="8">
            <button
              v-if="invite_only === true"
              class="btn button-secondary invite-button"
              @click="openInviteModal(item)"
            >
              {{ $t('invitation.invite_candidates') }}
            </button>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="6" class="col-donut">
        <div class="donut" :class="loading ? 'blur': ''">
          <b-button
            variant="outline-primary"
            size="sm"
            class="refresh-button"
            id="refresh-button"
            @click="refreshAssessmentData">
            <i class="fas fa-sync"></i>
          </b-button>
          <b-tooltip target="refresh-button" variant="light" triggers="hover">{{$t('errors.refresh')}}</b-tooltip>
          <donut-chart-component
            v-if="item.statistics"
            :statistics="item.statistics"
            :showInviteOnlyStats="invite_only || item.video_members"
             @refresh="refreshAssessmentData(true)"
             :loading="loading"
          ></donut-chart-component>
        </div>
        <div v-if="loading" class="loader-wrapper">
          <loading-inline
            class="d-flex justify-content-center mx-auto w-100"
          ></loading-inline>
        </div>
        <b-row align-h="center">
          <b-col
            cols="10"
            sm="8"
            v-if="!item.members || item.members.length > 0"
          >
            <router-link
              @click.native="
                setSelected();
                scrollToTop();
              "
              :to="{
                name: router_link_params.name,
                params: router_link_params.params,
              }"
            >
              <button
                class="btn btn-primary show-gameplays-button"
                data-dismiss="modal"
              >
                {{
                  $t(
                    'dashboard.recruitment_list_tab.full.button_open_gameplaylist',
                  )
                }}
              </button>
            </router-link>
          </b-col>
          <b-col v-else>
            <b-alert show variant="primary">{{
              $t('dashboard.recruitment_list_tab.full.warning_empty')
            }}</b-alert>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <invitation-modal-component
      v-if="showInvitationModal"
      :assessment="item"
      @closeModal="showInvitationModal = false"
    ></invitation-modal-component>
  </b-container>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import DonutChartComponent from './DonutChartComponent.vue';
import InvitationModalComponent from './InvitationModalComponent.vue';
import LoadingInline from './LoadingInline.vue';
import Gamelink from './Gamelink.vue';
import GameImage from './GameImage.vue';
import filters from '../common/filters';
import { getGameIcon } from '../common/constants';
import moment from 'moment';
import api from '../common/api.js';

export default {
  mixins: [filters],
  components: {
    Gamelink,
    GameImage,
    DonutChartComponent,
    InvitationModalComponent,
    LoadingInline,
  },
  props: {
    item: {
      required: true,
    },
    router_link_params: {
      required: true,
    },
  },
  data() {
    return {
      data_calculated: false,
      loading: false,
      open: false,
      finished: {
        percentage: null,
        value: null,
      },
      not_started: {
        percentage: null,
        value: null,
      },
      in_progress: {
        percentage: null,
        value: null,
      },
      total: null,
      showInvitationModal: false,
      endTime: null,
    };
  },
  computed: {
    ...mapState('user', ['user_lang']),
    ...mapState('select', ['selectedClient']),
    gameId() {
      return this.item.game;
    },
    invite_only() {
      return this.item.attributes.includes('INVITE_ONLY');
    },
    gamelinks() {
      return this.item.slug_links && this.item.slug_links.length > 0
        ? this.item.slug_links
        : [this.item.link];
    },
    assessmentOpen() {
      const now = new Date().getTime();
      const start = moment.utc(this.item.start_time).valueOf();
      const end = moment.utc(this.item.end_time).valueOf();

      switch (true) {
        case !start && !end:
          return true;
        case start && start < now && !end:
          return true;
        case start && start < now && end && end > now:
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    ...mapActions('select', ['setSelectedAssessment']),
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    setGameIcon(identifier) {
      return `${getGameIcon(identifier)}_icon_and_name.png`;
    },
    setSelected() {
      this.setSelectedAssessment(this.item);
    },
    openInviteModal() {
      this.setSelected();
      this.showInvitationModal = true;
    },
    openCloseAssessment() {
      const params = {};
      const now = new Date().getTime();
      const start = moment.utc(this.item.start_time).valueOf();
      const end = moment.utc(this.item.end_time).valueOf();
      // it means that is has set to open with the swith
      if (this.open) {
        params.end_time = null;
        if (now < start) {
          params.start_time = new Date().toISOString().slice(0, -1);
        }
      }
      // it means that is has set to closed with the swith
      if (!this.open) {
        params.end_time = new Date().toISOString().slice(0, -1);
      }
      api.setAssessmentTime(this.item.prefixed_id, params).then((r) => this.refreshAssessmentData(false));
    },
    refreshAssessmentData(loading) {
      this.loading = loading;
      api.getAssessmentStatistics(this.selectedClient.id).then((res) => {
        this.$store.commit('dashboard/setAssessments', res.data.client.assessments);
        const refreshedAssessment = this.$store.state.dashboard.assessments.filter((a) => a.id === this.item.id);
        this.setSelectedAssessment(refreshedAssessment[0]);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.open = this.assessmentOpen;
  },
};
</script>
<style>
.list {
  margin-top: 16px !important;
  width: 100%;
  margin-left: 0px !important;
  padding-bottom: 36px;
}
.duration,
.open-close {
  margin: 16px 0 16px 0;
}

.project-open {
  margin-right: 16px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1cc88a !important;
  background-color: #1cc88a !important;
}

.custom-control-input:checked:disabled ~ .custom-control-label::before {
  background-color: rgba(28, 200, 138, 0.6) !important;
}

.custom-control-input:checked:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.25);
}

.donut {
  margin-bottom: 16px;
  display:flex;
  flex-direction:column;
}

.donut.blur {
  opacity: 0.5;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
}

.refresh-button{
  align-self: flex-end;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.loader-wrapper {
  top: 15%;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  z-index: 9999;
  text-align: center;
}
.show-gameplays-button,
.invite-button {
  width: 100%;
  margin: 16px 0 16px 0;
  white-space: normal;
}
.item {
  padding: 36px !important;
}

@media screen and (max-width: 767px) {
  .item {
    padding: 18px 0px !important;
  }
  .col-donut {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
