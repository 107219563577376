<template>
  <!-- Modal -->
  <div id="modal-wrapper">
    <div
      id="invite-modal"
      class="invite"
    >
      <!-- Modal content -->
      <div class="modal-content modal-dialog modal-lg">
        <div class="modal-header card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">
            {{ $t('invitation.invite_modal.title', { title: assessment.name }) }}
          </h6>

          <button
            class="close"
            type="button"
          >
            <span
              class="close"
              @click="close"
            >&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <b-tabs v-model="tabIndex">
            <b-tab class="tab" active :title="$t('gameplay-table.applicants')">
              <loading-inline
                v-if="responseStatus==='loading'"
                 class="d-flex justify-content-center mx-auto w-100 loading-indicator"
                 :message="$t('authorization_in_progress')"
              ></loading-inline>
              <b-container class="invitation" :class="responseStatus=== 'loading' ? 'loading-blur' : ''">
                <b-row>
                  <ul class="invitation-instructions">
                    <li>{{ $t('invitation.invite_modal.instruction-1') }}</li>
                    <li>{{ $t('invitation.invite_modal.instruction-2') }}</li>
                    <li>{{ $t('invitation.invite_modal.instruction-3') }}</li>
                    <li>{{ $t('invitation.invite_modal.instruction-4') }}</li>
                    <li>{{ $t('invitation.invite_modal.instruction-5') }}</li>
                    <li>{{ $t('invitation.invite_modal.instruction-6') }}</li>
                  </ul>
                </b-row>
                <b-row>
                  <label
                    class="invitation-label"
                    for="email"
                  >
                    {{ $t('invitation.invite_modal.label') }}
                  </label>
                </b-row>
                <b-row>
                  <textarea
                    class="invitation-input"
                    :value="newInput"
                    @input="formatInput"
                  ></textarea>
                </b-row>
                <b-row>
                  <!-- All emails invalid (status code 400) -->
                  <b-alert
                    v-if="responseStatus === 'invalid'"
                    class="invitation-alert"
                    show
                    variant="danger"
                  >
                    <button
                      class="close"
                      type="button"
                      @click="responseStatus = null"
                    >×</button>

                    <p>
                      {{ $t('invitation.invite_modal.invalid') }}
                    </p>
                  </b-alert>

                  <!-- All emails successfully uploaded (status code 201) -->
                  <b-alert
                    v-if="responseStatus === 'success'"
                    class="invitation-alert"
                    show
                    variant="success"
                  >
                    <button
                      class="close"
                      type="button"
                      @click="responseStatus = null"
                    >×</button>

                    {{ $t('invitation.invite_modal.success_message', {
                      correct: response.correct.length,
                      all: toSend.length,
                      title: assessment.name
                    })}}
                  </b-alert>

                  <!-- All emails already authorized(status code 200) -->
                  <b-alert
                    v-if="responseStatus === 'alreadyAuthorized'"
                    class="invitation-alert"
                    show
                    variant="warning"
                  >
                    <button
                      class="close"
                      type="button"
                      @click="responseStatus = null"
                    >×</button>

                    {{ $t('invitation.invite_modal.already_authorized') }}
                  </b-alert>

                  <!-- Mixed response (status code 207) -->
                  <!-- If there are no invalid emails (only newly and already authorized), show sucess variant -->
                  <b-alert
                    v-if="responseStatus === 'warning' || responseStatus === 'mixed_success'"
                    class="invitation-alert"
                    show
                    :variant="responseStatus === 'warning' ? 'warning' : 'success'"
                  >
                    <button
                      class="close"
                      type="button"
                      @click="responseStatus = null"
                    >×</button>

                    {{ $t('invitation.invite_modal.mixed_warning_title', {
                      correct: response.correct.length,
                      all: toSend.length,
                      title: assessment.name
                    })}}

                    <div
                      v-if="response.already_authorized &&
                        response.already_authorized.length > 0"
                    >
                      {{ $t('invitation.invite_modal.mixed_warning_already_authorized') }}:
                      {{ response.already_authorized.length }}
                    </div>
                    <div v-if="response.invalid && response.invalid.length > 0">
                      {{ $t('invitation.invite_modal.mixed_warning_invalid') }}:

                      <ol>
                        <li
                          v-for="(element, index) in response.invalid"
                          :key="index"
                        >
                          {{ element.original }}
                        </li>
                      </ol>
                    </div>
                  </b-alert>

                  <!-- All other errors -->
                  <b-alert
                    v-if="responseStatus === 'error'"
                    class="invitation-alert"
                    show
                    variant="danger"
                  >
                    <button
                      class="close"
                      type="button"
                      @click="responseStatus = null"
                    >×</button>

                    <p>
                      {{ $t('invitation.invite_modal.error_message') }}
                    </p>
                  </b-alert>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab class="tab" :title="$t('invitation.accept_links')" :disabled ="acceptLinks.length === 0">
                <div v-if="acceptLinks.length > 0"
                  class="links">
                  <div class="tooltip-container">
                    <p>
                      <span v-if="copied" class="tooltiptext">{{ $t('gamelink-component.tooltip_text')}}</span>
                    </p>
                  </div>
                  <b-button
                    variant="outline-primary"
                    @click="onCopy">{{$t('copy_all')}}
                  </b-button>
                  <div class="table-container">
                    <table>
                    <tr v-for="(link, index) in acceptLinks" :key="index">
                      <td>{{link.stored.player.email}}</td>
                      <td>{{link.stored.accept_link}}</td>
                      </tr>
                    </table>
                  </div>
              </div>
            </b-tab>
            <b-tab class="tab" :title="$t('invitation.invite_modal.authorization_history')" :disabled ="history.length === 0">
                <div class="table-container logs">
                  <table class="table" v-if="history.length > 0">
                    <thead>
                      <tr>
                        <td>{{$t('email')}}</td>
                        <td>{{$t('action')}}</td>
                        <td>{{$t('date')}}</td>
                      </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in history" :key="index">
                      <td>{{item.email}}</td>
                      <td>{{item.action}}</td>
                      <td>{{item.date | moment}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </b-tab>
          </b-tabs>
        </div>
        <div class="modal-footer">
          <button
            v-if="tabIndex===0"
            class="btn btn-primary"
            :disabled="toSend.length  === 0 || responseStatus==='loading'"
            @click.stop="addPlayer"
          >
            {{ $t('invitation.invite_candidates') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import api from '../common/api';
import { RE_AUTHENTICATE } from '../common/constants';
import MatomoMixin from '../common/matomoMixin';
import LoadingInline from './LoadingInline.vue';
import filters from '../common/filters';

export default {
  components: {
    LoadingInline,
  },
  mixins: [MatomoMixin, filters],
  props: {
    assessment: {
      required: true,
    },
  },
  data() {
    return {
      responseStatus: null,
      response: {},
      acceptLinks: [],
      copied: false,
      showModal: true,
      newInput: '',
      toSend: [],
      invalidEmails: null,
      history: [],
      tabIndex: 0,
    };
  },
  methods: {
    addPlayer() {
      const payload = {
        invite_list: [...this.toSend],
      };
      this.responseStatus = null;
      this.response = {};
      this.responseStatus = 'loading';
      api
        .invitePlayer(payload, this.assessment.prefixed_id)
        .then((response) => {
          this.acceptLinks = response.data.data.filter((e) => e.status !== 400);
          let msg = '';
          switch (true) {
            case response.status === 201:
              this.responseStatus = 'success';
              this.response = {
                correct: response.data.data.filter((e) => e.status === 201),
              };
              this.setHistoryItems(this.response, new Date());
              // Send matomo event
              if (this.response.correct.length === 1) {
                msg = 'Authorized = 1';
              } else if (this.response.correct.length > 1 && this.response.correct.length <= 10) {
                msg = 'Authorized < 10';
              } else if (this.response.correct.length > 10 && this.response.correct.length <= 100) {
                msg = 'Authorized < 100';
              } else if (this.response.correct.length > 100) {
                msg = 'Authorized > 100';
              }
              this.trackMatomoEvent('Authorization', 'Successed', msg);
              break;

            case response.status === 200:
              this.responseStatus = 'alreadyAuthorized';
              const already_authorized = response.data.data.filter((e) => e.status === 200);
              this.setHistoryItems({ already_authorized }, new Date());
              msg = `Already authorized: ${already_authorized.length}`;
              this.trackMatomoEvent('Authorization', 'Failed', msg);
              break;

            case response.status === 207:
              this.response = {
                correct: response.data.data.filter((e) => e.status === 201),
                already_authorized: response.data.data.filter((e) => e.status === 200),
                invalid: response.data.data.filter((e) => e.status === 400),
              };
              this.responseStatus = this.response.invalid.length === 0 ? 'mixed_success' : 'warning';
              this.setHistoryItems(this.response, new Date());
              // Send matomo event
              if (this.response.correct.length === 1) {
                msg = msg.concat('Authorized = 1');
              } else if (this.response.correct.length > 1 && this.response.correct.length <= 10) {
                msg = msg.concat('Authorized < 10');
              } else if (this.response.correct.length > 10 && this.response.correct.length <= 100) {
                msg = msg.concat('Authorized < 100');
              } else if (this.response.correct.length > 100) {
                msg = msg.concat('Authorized > 100');
              }

              if (this.response.already_authorized.length && msg) {
                msg = msg.concat(' | ', `Already authorized: ${this.response.already_authorized.length}`);
              } else if (this.response.already_authorized.length) {
                msg = msg.concat(`Already authorized: ${this.response.already_authorized.length}`);
              }

              if (this.response.invalid.length && msg) {
                msg = msg.concat(' | ', `Invalid: ${this.response.invalid.length}`);
              } else if (this.response.invalid.length) {
                msg = msg.concat(`Invalid: ${this.response.invalid.length}`);
              }

              this.trackMatomoEvent('Authorization', 'Failed', msg);
              break;
            default:
              this.responseStatus = null;
              break;
          }
        })
        .catch((error) => {
          if (error.config && error.response && error.response.status === 400) {
            this.responseStatus = 'invalid';
            const invalid = error.response.data.data.filter((e) => e.status === 400);
            const msg = `Invalid: ${invalid.length}`;
            this.setHistoryItems({ invalid }, new Date());
            this.trackMatomoEvent('Authorization', 'Failed', msg);
          } else if (error.config && error.response && error.response.status === 401) {
            // eslint-disable-next-line no-restricted-globals
            RE_AUTHENTICATE(error.response.status, error.response.data);
          } else {
            const msg = error.config && error.response && error.response.status ? error.response.status : JSON.stringify(error);
            this.trackMatomoEvent('Authorization', 'Failed', msg);
            this.responseStatus = 'error';
          }
        });
    },
    close() {
      this.$emit('closeModal');
    },
    reset() {
      if (this.responseStatus === 'error' || this.responseStatus === 'success') {
        this.responseStatus = null;
      }
    },
    formatInput(e) {
      this.newInput = e.target.value.replace(/[ ;,]+/g, '\n');
      this.toSend = this.newInput.split(/,; \r\n|\r|\n/).filter((el) => el !== '');
    },
    onCopy() {
      const table = document.querySelector('.links .table-container');
      // check if ClipboardItem constructor is supported in browsser.
      if ((typeof ClipboardItem === 'function')) {
        // Convert the table element to plain text string
        const plainTextString = table.innerText;
        navigator.clipboard.write([
          new ClipboardItem({
            'text/plain': new Blob([plainTextString], {
              type: 'text/plain',
            }),
          }),
        ])
          .then(() => {
            this.copied = true;
            setTimeout(() => { this.copied = false; }, 1000);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        const range = document.createRange();
        range.selectNodeContents(table);
        const select = window.getSelection();
        select.removeAllRanges();
        select.addRange(range);
        document.execCommand('copy');
        this.copied = true;
        setTimeout(() => { this.copied = false; }, 1000);
      }
    },
    setHistoryItems(list, date) {
      const categories = Object.keys(list);
      categories.forEach((category) => {
        list[category].forEach((item) => {
          this.history.unshift({
            email: item.original,
            action: this.$t(`invitation.invite_modal.mixed_warning_${category}`),
            date,
          });
        });
      });
    },
  },
};
</script>

<style scoped>
.invitation-input {
  width: 100%;
  height: 300px;
  overflow-y: scroll;
}

.links, .logs {
  height: 529px;
  overflow: scroll;
  padding: 1rem;
}

.links table, .invitation.container {
  margin-top: 10px;
}

.links table td {
  white-space: nowrap;
  padding-right: 5px;
}

.tooltip-container .tooltiptext {
  display: inline;
  width: 120px;
  top: 20px;
  margin-left: 15px;
  background-color: rgba(33, 37, 41, 0.9);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.tooltip-container .tooltiptext:after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.loading-indicator {
  position: absolute;
  top: 50%;
  z-index: 1000;
}

.loading-blur {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
}

@media screen and (min-width: 768px) {
  .invitation-input {
    width: 100%;
  }
}

.invitation-input {
  width: 100%;
}

.invitation-label {
  font-weight: bold;
}

.invitation-alert {
  margin-top: 16px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
}

.invitation input.invalid {
  border-color: red;
  outline-color: #fdd;
}

.invitation p.invalid {
  color: red;
}

#modal-wrapper {
  position: absolute;
  z-index: 1040;
}

/* The Modal (background) */
.invite {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  z-index: 1040;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
