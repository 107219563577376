<template lang="html">
  <div id="app">
    <div id="wrapper">
      <!-- Fake Sidebar -->
     <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar"></ul>
      <!-- Content Wrapper -->
      <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <!-- Fake Topbar -->
        <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow"></nav>
        <!-- Begin Page Content -->
        <div class="container-fluid">
          <!-- Content Row -->
          <div class="row">
            <!-- Router View -->
            <error v-show="error===true"></error>
            <div v-if="err.response">
              <router-view :key="$route.fullPath"></router-view>
            </div>
            <b-col v-else-if="!err.response" cols="12" md="6" offset-md="3" class="text-center">
              <p class="lead text-gray-800 mb-5 error-heading">{{$t('errors.no_settings')}}</p>
            </b-col>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Error from './components/Error.vue';

export default {
  name: 'missing-settings-error',
  components: {
    Error,
  },
  props: ['errMsg', 'err'],
  beforeMount() {
    console.error(this.err);
  },
  computed: {
    ...mapState('dashboard', ['error']),
  },
};
</script>

<style lang="css" scoped>
.error-heading {
  font-size: 2rem;
}
</style>
