<template>
  <div class="search container-fluid">
    <filter-dates
      :language="user_lang"
      @on-search-by-dates="searchStats"
      :toggleButtonText="$t('filter-component.button_title')"
      searchFor="ClientStatistics"
      :defaultFrom="$store.state.dashboard.search_interval.from"
      :defaultTo="$store.state.dashboard.search_interval.to"
    ></filter-dates>
    <client-statistics
      :client="client"
      :selected="selected"
      :showInviteOnlyStats="showInviteOnlyStats"
    ></client-statistics>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ClientStatistics from './ClientStatistics.vue';
import FilterDates from './FilterDates.vue';

export default {
  components: {
    ClientStatistics,
    FilterDates,
  },
  name: 'statistics',
  props: {
    client: {
      required: true,
    },
    showInviteOnlyStats: {
      required: true,
    },
  },
  computed: {
    ...mapState('dashboard', ['clientTree']),
    ...mapState('user', ['user_lang']),
    ...mapState('select', ['selectedClient', 'selected']),
  },
  methods: {
    ...mapActions('dashboard', ['getStatistics']),
    searchStats(from, to) {
      this.$store.commit('dashboard/updateFrom', from);
      this.$store.commit('dashboard/updateTo', to);
      this.getStatistics({ clientId: this.client.id });
    },
  },
};
</script>
