<template>
  <div id="page-reload">
    <nav class="navbar navbar-light bg-white topbar mb-4 static-top shadow">
      <div class="benchmark_logo">
        <img src="/img/benchmark_logo.png" alt="" />
      </div>
    </nav>
    <div class="content">
      <div>
        <p v-html="$t('dashboard.page_reload_text_1', { countDown: countDown })"></p>
        <p>{{ $t('dashboard.page_reload_text_2') }}</p>
        <button class="default-button" @click="reloadPage">{{ $t('dashboard.page_reload_button') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    countDown: {
      required: true,
    },
  },
  methods: {
    reloadPage() {
      this.$emit('reload');
    },
  },
};
</script>
<style scoped>
#page-reload {
  background-image: url('../../public/img/benchmark_background.svg');
  position: fixed;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.benchmark_logo {
  margin: auto;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.benchmark_logo img {
  width: 145px;
  max-height: 33px;
  vertical-align: middle;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  font-size: 18px;
}

.content span {
    font-size: 30px;
}

.default-button {
  border: none;
  outline: none;
  color: white;
  align-items: center;
  padding: 10px 21px;
  left: 44.78%;
  right: 44.78%;
  top: 59%;
  bottom: 34.46%;
  background: linear-gradient(81.57deg, #0d86fe 12.9%, #b504ff 87.1%);
  box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 86px;
}

.default-button:focus {
  outline: none;
}
</style>
