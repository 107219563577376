<template>
  <div>
    <b-row>
      <b-col md="10" lg="8" offset-lg="2" offset-md="1">
        <barchart-component
          v-if="Object.keys(client.children).length > 0"
          :children="sorted_children"
          :selected="selected"
          :client="client"
          :show_own_projects="false"
          :showInviteOnlyStats="showInviteOnlyStats"
        ></barchart-component>
        <donut-chart-component
          v-if="Object.keys(client.children).length === 0"
          :statistics="client.statistics"
          :showInviteOnlyStats="showInviteOnlyStats"
        ></donut-chart-component>
      </b-col>
    </b-row>
    <button
      v-if="this.client.statistics"
      id="fake-button"
      data-toggle="modal"
      data-target="#chartModal"
    ></button>
    <b-row>
      <b-col md="10" lg="8" offset-lg="2" offset-md="1">
        <div
          class="table-responsive stat-table"
          id="table-wrapper"
          @scroll="onScroll"
          v-if="Object.keys(client.children).length > 0"
        >
          <div>
            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th>{{ $t('dashboard.statistics_tab.statistics_table.order') }}</th>
                  <th>{{ $t('dashboard.statistics_tab.statistics_table.levels') }}</th>
                  <th colspan="2">
                    {{ $t('dashboard.statistics_tab.statistics_table.finished_gamelays') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(child, index) in sorted_children" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ child.name }}</td>
                  <td v-if="Object.keys(child.children).length === 0">
                    {{ child.statistics.finished_gameplays }}
                  </td>
                  <td v-if="Object.keys(child.children).length > 0">
                    {{ child.statistics.finished_gameplays_recursive }}
                  </td>
                  <td>
                    <router-link :to="{ path: `/client/${child.id}` }">
                      <button @click="scrollToTop()" class="btn button-secondary">
                        {{ $t('dashboard.statistics_tab.statistics_table.open_client') }}
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="scrollable" class="overlay"></div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="chartModal"
      size="lg"
      v-model="showBarchartModal"
      :title="subclient_name"
      hide-footer
    >
      <barchart-component
        v-if="subclient_modal_data.children && subclient_modal_data.children.length > 0"
        :children="subclient_modal_data.children"
        :client="subclient_modal_data.client"
        :show_own_projects="true"
        :showInviteOnlyStats="showInviteOnlyStats"
      ></barchart-component>
      <b-alert
        v-if="subclient_modal_data.children && subclient_modal_data.children.length === 0"
        show
        variant="primary"
        >{{ $t('dashboard.statistics_tab.modal.no_sublevels') }}</b-alert
      >
    </b-modal>
  </div>
</template>
<script>
import BarchartComponent from './BarchartComponent.vue';
import DonutChartComponent from './DonutChartComponent.vue';

export default {
  components: {
    BarchartComponent,
    DonutChartComponent,
  },
  data() {
    return {
      showBarchartModal: false,
      subclient_modal_data: {
        client: null,
        children: null,
      },
      scrollable: false,
    };
  },
  props: {
    client: {
      required: true,
    },
    selected: {
      required: true,
    },
    showInviteOnlyStats: {
      required: true,
    },
  },
  computed: {
    sorted_children() {
      // convert object of children into array to sort
      const keys = Object.keys(this.client.children);
      const array = [];
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i];
        array.push(this.client.children[k]);
      }
      return array.sort(this.sortByFinished);
    },
    subclient_name() {
      return this.subclient_modal_data.client === null ? '' : this.subclient_modal_data.client.name;
    },
  },
  methods: {
    sortByFinished(a, b) {
      const column_a = Object.keys(a.children).length > 0 ? 'finished_gameplays_recursive' : 'finished_gameplays';
      const column_b = Object.keys(b.children).length > 0 ? 'finished_gameplays_recursive' : 'finished_gameplays';
      if (a.statistics[column_a] < b.statistics[column_b]) return 1;
      if (a.statistics[column_a] > b.statistics[column_a]) return -1;
      return 0;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
      });
    },
    setSubClientData(client, children) {
      this.subclient_modal_data.client = client;
      this.subclient_modal_data.children = children;
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        this.scrollable = false;
      } else if (scrollTop + clientHeight < scrollHeight && this.scrollable === false) {
        this.scrollable = true;
      }
    },
    checkScrollability() {
      const content = document.getElementById('table-wrapper');
      if (content) {
        this.scrollable = content.scrollHeight > content.clientHeight;
      } else {
        this.scrollable = false;
      }
    },
  },
  mounted() {
    const self = this;
    document.getElementById('fake-button').addEventListener('click', () => {
      if (!self.showBarchartModal) {
        const value = document.getElementById('fake-button').innerHTML;
        const client = self.sorted_children[value];
        const keys = Object.keys(self.sorted_children[value].children);
        const sub_children_array = [];
        for (let i = 0; i < keys.length; i++) {
          const k = keys[i];
          sub_children_array.push(self.sorted_children[value].children[k]);
        }
        const children = sub_children_array.sort(self.sortByFinished);
        self.setSubClientData(client, children);
        self.showBarchartModal = true;
      }
    });

    this.checkScrollability();
  },
};
</script>
<style scoped>
#fake-button {
  visibility: hidden;
}
.stat-table {
  min-height: 254px;
  max-height: 500px;
}
.table-wrapper {
  position: relative;
}
.overlay {
  position: absolute;
  bottom: 0;
  height: 16vh;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(hsla(0, 0%, 100%, 0)),
    color-stop(50%, hsla(0, 0%, 100%, 0.7))
  );
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.7) 50%);
  border-radius: 0 0 8px 8px;
  pointer-events: none;
}
</style>
