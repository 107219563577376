import axios from 'axios';
import { APIV2_PATH, AS_API_PATH } from './constants';
import { axios_main_instance, axios_new_instance } from './axios-interceptor';
import store from './store';

export default {
  fetchVersionTxt() {
    const requestUrl = `/version.txt?timestamp=${new Date().getTime()}`;
    return axios.get(requestUrl);
  },
  getClient() {
    // workaround in case if a user comes from an assembled auth link APIV2_PATH remain null,
    // because variables from constants.js get undefined in the early stage of app load
    if (!APIV2_PATH) window.location.reload();
    return axios_main_instance.get(`${APIV2_PATH}clients/`);
  },
  getAssessmentCandidates(assessmentId, requestParams) {
    const allParams = { lang: store.state.user.user_lang, ...requestParams };
    return axios_main_instance.get(`${APIV2_PATH}assessments/${assessmentId}/candidates/`, { params: allParams });
  },
  getAssessmentStatistics(clientId) {
    return axios_main_instance.get(`${APIV2_PATH}clients/${clientId}/assessment-statistics?timestamp=${new Date().getTime()}&force=true`);
  },
  getAssessmentById(assessmentId, lang) {
    return axios_main_instance.get(`${APIV2_PATH}assessments/${assessmentId}/?lang=${lang}`);
  },
  setAssessmentTime(assessmentId, params) {
    return axios_main_instance.post(`${APIV2_PATH}assessments/${assessmentId}/set-time/`, params);
  },
  getASUserInfo(token) {
    // instead of axios_main_instance this request uses axios root instance,
    // bacuase somehow variables from constants.js get undefined in the early stage of app load
    // TODO: fix constants import
    return axios.get(
      `${AS_API_PATH}openid/userinfo`,
      { headers: { Authorization: `Bearer ${token}` } },
    );
  },
  getASUserSettings(token) {
    return axios.get(
      `${AS_API_PATH}settings/`,
      { headers: { Authorization: `Bearer ${token}` } },
    );
  },
  getUser(token, userId) {
    // instead of axios_main_instance this request uses axios root instance,
    // bacuase somehow variables from constants.js get undefined in the early stage of app load
    return axios.get(
      `${APIV2_PATH}users/${userId}/`,
      { headers: { Authorization: `Bearer ${token}` } },
    );
  },
  getStatistics(params) {
    const subclient_depth = params.subclient_depth || 3;
    const { from } = store.state.dashboard.search_interval;
    const { to } = store.state.dashboard.search_interval;
    if (from && to) {
      return axios_main_instance.get(`${APIV2_PATH}clients/${params.clientId}/statistics/?subclient_depth=${subclient_depth}&filter_by={"start_time_from":"${from}","start_time_to":"${to}"}&timestamp=${new Date().getTime()}`);
    }

    return axios_main_instance.get(`${APIV2_PATH}clients/${params.clientId}/statistics/?subclient_depth=${subclient_depth}&timestamp=${new Date().getTime()}`);
  },
  invitePlayer(payload, assessmentId) {
    return axios_new_instance.post(`${APIV2_PATH}assessments/${assessmentId}/invite/`, payload, {
      headers: { 'Content-Type': 'application/json' },
    });
  },
  generateCSV(assessmentId) {
    return axios_main_instance.get(`${APIV2_PATH}assessments/${assessmentId}/csv-create/?send_email=true`);
  },
  downloadCSV(url) {
    return axios.get(
      APIV2_PATH + url,
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'text/csv',
        },
      },
    );
  },
};
