<template>
  <b-col md="10" offset-md="1">
    <div v-for="assessment in assessments" :key="assessment.id" class="card shadow mb-4 list">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">{{ assessment.name }}</h6>
      </div>
      <div class="card-body">
        <recruitment-list-full-item
          :item="assessment"
          :router_link_params="getRouterLinkParams(assessment)"
        ></recruitment-list-full-item>
      </div>
    </div>
  </b-col>
</template>
<script>
import RecruitmentListFullItem from './RecruitmentListFullItem.vue';

export default {
  components: {
    RecruitmentListFullItem,
  },
  props: {
    assessments: {
      required: true,
    },
  },
  methods: {
    getRouterLinkParams(assessment) {
      const name = 'Aggregator';
      const params = {};
      params[`${name.toLowerCase()}Id`] = assessment.id;
      return { name, params };
    },
  },
};
</script>
<style scoped>
.recruitment-table {
  margin: 16px !important;
}

.header-column {
  vertical-align: bottom !important;
}
.header-column:nth-child(3) {
  width: 150px;
}
</style>
