<template>
  <div>
    <div
      class="chart-wrapper"
      :id="`chart-wrapper${client.id}`"
      :style="chartwrapperStyle"
      @scroll="onScroll"
    >
      <barchart
        v-if="client.statistics"
        :id="'chart' + client.id"
        :options="barchartOptions"
        :chartData="barchartData"
        :plugins="plugins"
        :style="
          `margin-top: 32px;
          margin-bottom: 32px;
          width:100%;
          min-height: 400px;
          height:${barchartData.labels.length * 37 * 2 + 50}px;`
        "
      ></barchart>
      <div
        v-if="hovering"
        id="barchart-tooltip"
        :style="{ left: tooltip.left, top: tooltip.top }">
        {{tooltip.content}}
      </div>
    </div>
    <div v-if="scrollable" class="overlay"></div>
  </div>
</template>
<script>
import Barchart from './chartjs/barchart';

export default {
  components: {
    Barchart,
  },
  props: {
    children: {
      required: true,
    },
    client: {
      required: true,
    },
    show_own_projects: {
      required: true,
    },
    showInviteOnlyStats: {
      required: true,
    },
  },
  data() {
    return {
      hovering: false,
      tooltip: {
        content: '',
        left: null,
        top: null,
      },
      barchartData: {
        labels: [],
        datasets: [
          {
            key: ['finished_detailed', 'report_ok'],
            label: this.$t('dashboard.statistics_tab.legend.completed'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.completed_desc'),
            backgroundColor: '#01b8aa',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: 'rgba(133, 135, 150, 1)',
            data: [],
            barThickness: 37,
            barPercentage: 0.5,
            pointStyle: 'circle',
            cursor: 'pointer',
          },
          {
            key: ['finished_detailed', 'report_warning'],
            label: this.$t('dashboard.statistics_tab.legend.incomplete_result'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.incomplete_result_desc'),
            backgroundColor: '#f2c80f',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: 'rgba(133, 135, 150, 1)',
            data: [],
            barThickness: 37,
            barPercentage: 0.5,
            pointStyle: 'circle',
            cursor: 'pointer',
          },
          {
            key: ['finished_detailed', 'report_error'],
            label: this.$t('dashboard.statistics_tab.legend.quit_after_onboarding'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.quit_after_onboarding_desc'),
            backgroundColor: '#f87979',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: 'rgba(133, 135, 150, 1)',
            data: [],
            barThickness: 37,
            barPercentage: 0.1,
            pointStyle: 'circle',
          },
          {
            key: 'in_progress_finished_gameplays',
            label: this.$t('dashboard.statistics_tab.legend.in_progress_finished'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.in_progress_finished_desc'),
            backgroundColor: 'rgb(162, 69, 255)',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: 'rgba(133, 135, 150, 1)',
            data: [],
            barThickness: 37,
            barPercentage: 0.1,
            pointStyle: 'circle',
          },
          {
            key: 'in_progress_gameplays',
            label: this.$t('dashboard.statistics_tab.legend.in_progress'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.in_progress_desc'),
            backgroundColor: 'rgba(95, 135, 255, 1)',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: 'rgba(133, 135, 150, 1)',
            data: [],
            barThickness: 37,
            barPercentage: 0.1,
            pointStyle: 'circle',
          },
          {
            key: 'not_started_gameplays',
            label: this.$t('dashboard.statistics_tab.legend.empty_attempt'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.empty_attempt_desc'),
            backgroundColor: 'rgba(196, 209, 255, 1)',
            pointBorderColor: 'rgba(133, 135, 150, 1)',
            borderWidth: 1,
            data: [],
            barThickness: 37,
            barPercentage: 0.5,
            pointStyle: 'circle',
            cursor: 'pointer',
          },
          {
            key: 'unused',
            label: this.$t('dashboard.statistics_tab.legend.unused_invitation'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.unused_invitation_desc'),
            backgroundColor: 'rgba(233, 239, 253, 1)',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: 'rgba(133, 135, 150, 1)',
            data: [],
            barThickness: 37,
            barPercentage: 0.5,
            pointStyle: 'circle',
            cursor: 'pointer',
          },
          {
            key: ['finished_detailed', 'report_not_ready'],
            label: this.$t('dashboard.statistics_tab.legend.not_ready'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.not_ready_desc'),
            backgroundColor: '#a245ff',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: 'rgba(133, 135, 150, 1)',
            data: [],
            barThickness: 37,
            barPercentage: 0.5,
            pointStyle: 'circle',
            cursor: 'pointer',
          },
        ],
      },
      sorted_children: [],
      chart: {},
      scrollable: false,
    };
  },
  computed: {
    chartwrapperStyle() {
      return this.barchartData.labels.length > 4
        ? {
          height: '400px',
          'overflow-x': 'hidden',
          'overflow-y': 'scroll',
        }
        : '';
    },
    barchartOptions() {
      const self = this;
      return {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback(label) {
                  if (/\s/.test(label) && window && window.innerWidth < 992) {
                    return label.split(' ');
                  }
                  return label;
                },
              },
              gridLines: {
                display: false,
              },
              stacked: true,
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
              scaleLabel: {
                display: true,
                padding: 0,
                lineHeight: 1,
                labelString: this.$t('dashboard.statistics_tab.axis_title'),
              },
              stacked: true,
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
          },
          onHover(event, legendItem) {
            if (self.hovering) {
              return;
            }
            self.hovering = true;
            self.tooltip.content = self.barchartData.datasets[legendItem.datasetIndex].labelTooltip;
            self.tooltip.left = `${event.x}px`;
            self.tooltip.top = `${event.y}px`;
          },
          onLeave() {
            self.hovering = false;
          },
        },
        tooltips: {
          enabled: true,
          titleFontColor: 'black',
          mode: 'nearest',
          backgroundColor: '#eee',
          bodyFontColor: 'black',
          borderWidth: 1,
          borderColor: '#666666',
          callbacks: {
            // Toltip content
            label(tooltipItem, data) {
              const label = `${data.datasets[tooltipItem.datasetIndex].label}: ${
                tooltipItem.value
              }`;
              return label;
            },
          },
        },
        onHover: (e, chartElement) => {
          const event = e;
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        title: {
          display: true,
          text: this.$t('dashboard.statistics_tab.barchart_title'),
          fontColor: '#333333',
          fontSize: 18,
          fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
        },
        onClick: this.handleClick,
        responsive: true,
        maintainAspectRatio: false,
        height: 200,
      };
    },
    plugins() {
      return [
        {
          beforeTooltipDraw: (chart) => {
            // Display value within each bar
            const chartInstance = chart;
            const { ctx } = chartInstance;
            ctx.textAlign = 'center';
            ctx.font = 'bold 12px "Avenir", Helvetica, Arial, sans-serif';
            ctx.fillStyle = 'rgba(0, 0, 0, 1)';
            ctx.textBaseline = 'bottom';

            chart.data.datasets.forEach((dataset, i) => {
              const meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const data = dataset.data[index];
                if (data > 0) {
                  const center = bar._model.base + (bar._model.x - bar._model.base) / 2;
                  ctx.fillText(data, center, bar._model.y + 7);
                }
              });
            });
          },
        },
      ];
    },
  },
  methods: {
    setChartData() {
      // hide unused inivitation category when the client doesn't have invite only assessment(s)
      if (!this.showInviteOnlyStats) {
        this.barchartData.datasets.splice(5, 1);
      }

      // hide the not ready data category when client or children does not have data there
      const showNotReadyStats = this.show_own_projects ? (this.client.statistics.finished_detailed.report_not_ready_recursive > 0)
        : (this.client.statistics.finished_detailed.report_not_ready > 0);

      if (!showNotReadyStats) {
        this.barchartData.datasets.pop();
      }
      const chartData = this.barchartData.datasets;

      // Own client (own clients statistics are shown only if client has a parent)
      if (this.show_own_projects) {
        // Add client name as label
        this.addCategory(
          `${this.client.name} ${this.$t(
            'dashboard.statistics_tab.barchart_own_recruitments_label',
          )}`,
        );
        // Add data
        chartData.forEach((item, index) => {
          if (typeof item.key === 'string') {
            this.addData(index, this.client.statistics[item.key]);
          } else {
            // This is for nested properties e.g. finished_detailed
            this.addData(index, this.client.statistics[item.key[0]][item.key[1]]);
          }
        });
      }

      // Children
      for (let i = 0; i < this.children.length; i++) {
        // Add subclient names
        this.addCategory(this.children[i].name);
        // Add subclient data
        chartData.forEach((item, index) => {
          const recursiveSuffix = Object.keys(this.children[i].children).length > 0 ? '_recursive' : '';
          if (typeof item.key === 'string') {
            this.addData(index, this.children[i].statistics[`${item.key}${recursiveSuffix}`]);
          } else {
            this.addData(
              index,
              this.children[i].statistics[item.key[0]][`${item.key[1]}${recursiveSuffix}`],
            );
          }
        });
      }
    },
    addCategory(category) {
      this.barchartData.labels.push(category);
    },
    addData(datasetCategoryIndex, value) {
      this.barchartData.datasets[datasetCategoryIndex].data.push(value);
    },
    handleClick(event, activeElements) {
      if (activeElements && activeElements.length > 0 && activeElements[0]._index !== undefined) {
        const index = activeElements[0]._index;
        const fakeButton = document.getElementById('fake-button');
        fakeButton.innerHTML = index;
        fakeButton.click();
      }
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        this.scrollable = false;
      } else if (scrollTop + clientHeight < scrollHeight && this.scrollable === false) {
        this.scrollable = true;
      }
    },
    checkScrollability() {
      const content = document.getElementById(`chart-wrapper${this.client.id}`);
      if (content) {
        setTimeout(() => {
          this.scrollable = (content.scrollHeight > content.clientHeight, 100);
        });
      } else {
        this.scrollable = false;
      }
    },
  },
  created() {
    if (this.children !== null && this.client !== null) {
      this.setChartData();
    }
  },
  mounted() {
    this.checkScrollability();
  },
};
</script>
<style lang="scss" scoped>
#barchart-tooltip {
  color: inherit;
  text-align: left;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  padding: 0.5rem 0.75rem;
  background-color: white;
  z-index: 1;
  margin-top: 3px;
  max-width: 300px;
  position: fixed;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 12px;
}

.barchart {
  margin-bottom: 32px;
}
.chart-wrapper {
  position: relative;
}
.overlay {
  position: absolute;
  bottom: 0;
  height: 8vh;
  width: calc(100% - 1rem);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(hsla(0, 0%, 100%, 0)),
    color-stop(50%, hsla(0, 0%, 100%, 0.7))
  );
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.7) 50%);
  border-radius: 0 0 8px 8px;
  pointer-events: none;
}
</style>
