<template>
  <li :id="client.name" class="sidebar-nav-item nav-item">
    <!-- Client link without child clients -->
    <router-link
      v-if="!(Object.keys(client.children).length>0)"
      class="nav-link"
      :class="{ child : childNavItem }"
      :to="`/client/${client.id}`"
      @click.native="selectClient()"
    >
      <span>{{client.name}}</span>
    </router-link>

    <!-- Client link with child clients -->
    <div
      v-else
      class="client-with-childs"
      :class="{ sidebarToggled: sidebarToggled && !childNavItem }"
    >
      <!-- Client link -->
      <router-link
        @click.native="selectClient()"
        class="nav-link"
        :class="childNavItem ? 'child' : 'parent'"
        :to="`/client/${client.id}`"
        aria-expanded="true"
        :aria-controls="'collapse'+client.id"
      >
        <span>{{client.name}}</span>
      </router-link>

      <!-- Dropdown button -->
      <i
        class="fa"
        :class="`${dropdownVisible ? 'fa-caret-up' : 'fa-caret-down'} ${childNavItem ? 'child' : 'parent'}`"
        @click="toggleDropdown"
      ></i>
    </div>

    <!-- Child clients -->
    <b-collapse
      v-if="Object.keys(client.children).length>0"
      :id="'collapse'+client.id"
      class="child-collapse"
      :class="{ sidebarChild : childNavItem && sidebarToggled }"
      :aria-labelledby="'heading'+client.id"
      v-model="dropdownVisible"
    >
      <div class="bg-white py-2 collapse-inner rounded">
        <side-nav-item
          v-for="(child) in client.children"
          :key="child.index"
          :client="child"
          :sidebarToggled="sidebarToggled"
          childNavItem
          @select-client="selectChildClient($event)"
        ></side-nav-item>
      </div>
    </b-collapse>
  </li>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';
import { BCollapse } from 'bootstrap-vue';

Vue.use(VueRouter);

export default {
  name: 'SideNavItem',

  components: {
    'b-collapse': BCollapse,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
    sidebarToggled: {
      require: true,
    },
    childNavItem: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      dropdownVisible: false,
    };
  },

  methods: {
    selectClient() {
      this.$emit('select-client', this.client);
    },

    selectChildClient(client) {
      this.$emit('select-client', client);
    },

    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
  },
};
</script>

<style lang="scss" scoped>

.child-collapse.sidebarChild {
  display: contents;
}

.sidebar-nav-item {
  list-style-type: none;

  .nav-link {
    font-size: 0.85rem;
    margin: 0px;
    padding: 8px 16px 8px 16px !important;
    text-align: left;
  }

  .nav-link.child {
    color: rgba(0,0,0,0.8);
  }

  .nav-link :active {
    color: #fff !important;
  }

  .nav-link span {
    font-size: 0.85rem;
    display: inline;
  }

  .nav-link span:hover {
    text-decoration: underline;
  }

  .client-with-childs {
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      cursor: pointer;
      padding: 0.5rem 1rem;
      color: rgba(255,255,255,0.8);
    }

    i.child {
      color: rgba(0,0,0,0.5);
    }

    i:hover {
      color: rgba(255,255,255,1.0);
    }

    i:hover.child {
      color: rgba(0,0,0,1.0);
    }
  }

  .client-with-childs.sidebarToggled {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    a {
      padding-bottom: 0px !important;
    }

    i {
      cursor: pointer;
      padding: 0rem 1rem;
      color: rgba(255,255,255,0.8);
    }
  }

  .collapse {
    position: unset;
    padding-inline-start: 5px;
    padding-right: 5px;
    border-radius: 0.35rem;
    -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    margin: 0;

    .collapse-inner {
      margin: 1rem 0 0 0;
      margin-top: 0px;
      overflow: auto;
    }
  }

  .collapse, .collapse-inner {
    white-space: normal;
  }
}

// Mobile screen style changes
@media screen and (max-width: 767px) {
  .nav-item .nav-link {
    width: 100%;
    margin: 0 8px;
  }

  .collapse {
    .collapse-inner{
      margin-bottom: 1rem;
    }
  }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

</style>
