<template>
 <b-alert class="error-message text-center" show variant="danger">{{$t('errors.general')}}<span> <b-button @click="refresh()" class="button btn">{{$t('errors.refresh')}}<i class="fas fa-sync"></i></b-button></span></b-alert>
</template>
<script>
export default {
  methods: {
    refresh() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.error-message{
    font-size:1rem;
    width: 100%;
    margin: 20px auto;
    overflow: hidden;
    padding: 10px 0;
    align-items: center;
}
.error-message i{
    margin-left:20px;
}
 .btn:hover, .btn:active, .btn:focus, .btn.focus {
    background-color: #721c24;
    color:white;
    border:none;
    }
.error-message button{
    margin-left: 20px;
    color: #721c24;
    border-color: #721c24;
    background:transparent;
    display:inline;
}
</style>
