<template>
  <b-breadcrumb>
    <b-breadcrumb-item
      v-for="(item, index) in items"
      :key="index"
      @click="setSelectedClient(item.to.client.id)"
      :item="item"
      :to="item.to"
      :text="item.text"
      :active="item.active"
    ></b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    selectedAssessment: { required: true },
    index_of_clients: { required: true },
    clientTree: { required: true },
  },
  computed: {
    ...mapState('select', ['selectedClient']),
    client_tree() {
      const items_data = [];
      const client_data = this.index_of_clients[this.selectedClient.id];

      items_data.push(this.clientTree[client_data.first_level_id]);

      if (client_data.second_level_id !== null) {
        items_data.push(
          this.clientTree[client_data.first_level_id].children[client_data.second_level_id],
        );
      }
      if (client_data.third_level_id !== null) {
        items_data.push(
          this.clientTree[client_data.first_level_id].children[client_data.second_level_id]
            .children[client_data.third_level_id],
        );
      }
      return items_data;
    },
    items() {
      const items = [];
      let client_link = null;
      let gameplay_table_nav_link = null;
      switch (true) {
        case this.$route.name === 'Dashboard' || this.$route.name == 'Home':
          for (let i = 0; i < this.client_tree.length; i++) {
            const item_info = {
              text: this.client_tree[i].name,
              to: {
                path: `/client/${this.client_tree[i].id}`,
                client: this.client_tree[i],
              },
              active: this.client_tree[i].id === this.selectedClient.id,
            };
            const active = this.client_tree[i].id === this.selectedClient.id;
            item_info.active = active;
            items.push(item_info);
          }
          return items;
          break;
        case this.$route.name === 'Aggregator':
          client_link = {
            text: this.selectedClient.name,
            to: {
              path: `/client/${this.selectedClient.id}`,
              client: this.selectedClient,
            },
            active: false,
          };
          gameplay_table_nav_link = {
            text: this.selectedAssessment.name,
            to: '',
            active: true,
          };
          items.push(client_link);
          items.push(gameplay_table_nav_link);
          return items;
          break;
        default:
          return null;
      }
    },
  },
  methods: {
    ...mapActions('select', ['setSelectedClient']),
  },
};
</script>

<style scoped>
.breadcrumb {
  background-color: inherit;
}
</style>
