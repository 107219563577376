import store from './store';
import { router } from './router';

export const selectState = {
  selectedClient: null,
  topClient: null,
  selectedAssessment: null,
};

export const selectMutations = {
  setSelectedAssessment(state, data) {
    state.selectedAssessment = data;
  },

  setSelectedClient(state, clientId) {
    const { clientTree } = store.state.dashboard;
    for (const client in clientTree) {
      if (Number(client) === clientId) {
        state.selectedClient = clientTree[client];
        return;
      }

      for (const child in clientTree[client].children) {
        if (Number(child) === clientId) {
          state.selectedClient = clientTree[client].children[child];
          return;
        }

        for (const grandchild in clientTree[client].children[child].children) {
          if (Number(grandchild) === clientId) {
            state.selectedClient = clientTree[client].children[child].children[grandchild];
            return;
          }
        }
      }
    }
    if (state.selectedClient === null || state.selectedClient.id !== clientId) {
      // TEMPORARY solution to get 404 if wrong client is requested by the user, until API endpoint for 1 client gets ready
      router.push('/404');
    }
  },
};

export const selectActions = {
  setSelectedAssessment({ commit }, assessment) {
    commit('setSelectedAssessment', assessment);
  },
  setSelectedClient({ commit }, clientId) {
    commit('setSelectedClient', clientId);
  },
};
