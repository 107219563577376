<template>
  <!-- Topbar -->
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <!-- Sidebar Toggle (Topbar) -->
    <button
      id="sidebarToggleTop"
      class="btn btn-link d-md-none rounded-circle mr-3"
      @click.prevent="toggleSidebar()"
    >
      <i class="fa fa-bars"></i>
    </button>

    <!-- Client logo -->
    <div class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
      <img
        v-if="client && client.logo_url"
        class="client-logo"
        :src="client.logo_url"
        alt=""
      >
    </div>

    <!-- Client name -->
    <div
      v-if="client &&
        client !== null"
      id="client"
    >
      <router-link :to="{path:`/client/${client.id}`}">
         <h3>
          {{ client.name }}
        </h3>
      </router-link>
    </div>

    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
      <b-dropdown
        id="account-dropdown"
        toggle-class="text-decoration-none"
        variant="link"
      >
        <template slot="button-content">
          <i class="fas fa-user user-icon"></i>
        </template>
        <b-dropdown-item
          href="#"
          @click="logout()"
        >
          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>

          {{$t('navbar.logout')}}
        </b-dropdown-item>
      </b-dropdown>
    </ul>
  </nav>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import { LOGOUT_PATH } from '../common/constants.js';

export default {
  components: {
    'b-dropdown': BDropdown,
    'b-dropdown-item': BDropdownItem,
  },
  props: {
    client: {
      required: true,
    },
  },
  data() {
    return {
      route: this.$route.path,
    };
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggle-sidebar');
    },

    logout() {
      localStorage.clear();
      location.href = LOGOUT_PATH;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  .client-logo {
    height: auto;
    width: auto;
    max-height: 60px;
    max-width: 100px;
  }

  .user-icon {
    border-radius: 50%;
    color: #4e73df;
    border: 1px solid #4e73df;
    padding: 8px;
    background-color: #f8f9fc;
  }

  #client {
    color: #007bff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;
    text-align: center;

    h3 {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    position: fixed !important;
    width: 100%;
    z-index: 2;
    top: 0;

    h3 {
      font-size: 1rem;
    }
  }
}
</style>

<style lang="scss">
// Hide the caret
#account-dropdown {
  button::after {
    display: none;
  }
}
</style>
