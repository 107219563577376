<template>
  <div id="loading-inline" class="d-flex flex-column align-items-center my-4">
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ $t('loading') }}</span>
    </div>
    <p v-if=!spinner_only class="mt-2">{{ message || $t('loading') }}</p>
  </div>
</template>

<script>
export default {
  props: {
    spinner_only: {
      required: false,
    },
    message: {
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
