import Vue from 'vue';

import VueI18n from 'vue-i18n';
import moment from 'moment';
import api from './common/api.js';
import store from './common/store.js';
import { RE_AUTHENTICATE, LOGOUT_PATH } from './common/constants';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getBrowserLanguage() {
  const userLang = navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2);
  return userLang;
}

export function getUserLang() {
  if (window.localStorage.getItem('settings') && window.localStorage.getItem('access_token')) {
    return new Promise((resolve, reject) => {
      api.getASUserInfo(window.localStorage.getItem('access_token'))
        .then((result) => {
          resolve(
            api.getASUserSettings(window.localStorage.getItem('access_token'))
              .then((resultSetting) => {
                if (resultSetting.data && resultSetting.data.global && resultSetting.data.global.language) {
                  setUserLang(resultSetting.data.global.language);
                } else {
                  setUserLang("english");
                }
                setDateLocale(),
                window.localStorage.setItem('userinfo', true),
                store.commit('user/setUserinfo', result.data),
                store.commit('user/setUserLoading', false),
                // Get user data
                api.getUser(window.localStorage.getItem('access_token'), result.data.sub).then((getUserResult) => {
                  store.commit('user/setUserData', getUserResult.data);
                })
              }),
          );
        })
        .catch((error) => {
          if (error.response) {
            store.commit('user/setUserLang', getBrowserLanguage());
            if (error.response.status === 401) {
              // Reauthenticate user or logout
              const reAuthenticatedAt = localStorage.getItem('ReAuthenticated');
              const diff = new Date().getTime() - reAuthenticatedAt;
              if (!reAuthenticatedAt || (reAuthenticatedAt && diff > 5000)) {
                RE_AUTHENTICATE(error.response.status, error.response.data);
              } else {
                // this can be useful when scope is invalid, or in case of a reauthentication loop
                localStorage.clear();
                location.href = LOGOUT_PATH;
              }
            }
          } else {
            store.commit('user/setUserLang', getBrowserLanguage());
            // in case of error, set browserlanguage as user language
            setUserLang(null);
            store.commit('dashboard/setError'); // display a general error message to the user
            console.error({
              error,
            });
            reject(error);
          }
        });
    });
  }
  if (store) {
    store.commit('user/setUserLang', getBrowserLanguage());
  } else {
    getBrowserLanguage();
  }
}

function setUserLang(lang) {
  const l = (lang === null) ? getBrowserLanguage() : lang;
  i18n.locale = l;
  if (store) {
    store.commit('user/setUserLang', l);
  }
}

function setDateLocale() {
  moment.locale(i18n.locale || i18n.fallbackLocale);
}

export function setFallbackLocale() {
  store.commit('user/setFallbackLocale', 'en');
  return 'en';
}

export const i18n = new VueI18n({
  locale: getUserLang(),
  fallbackLocale: store ? setFallbackLocale() : 'en',
  messages: loadLocaleMessages(),
});
