<template>
    <div class="search">
      <button class="btn search-button filter-options" @click="closed=!closed">
        {{ toggleButtonText }}
        <i class="fas fa-caret-down" :class="{expanded: !closed}"></i>
      </button>
      <b-row class="form-group row" v-if="!closed">
        <b-col sm="12" md="10" offset-md="2">
          <button v-for="(option, i) in rangeOptions"
            :key="i"
            class="btn filter-options button-secondary filter"
            @click="setSearchDate(option)">
              {{$t(`filter-component.options.${option}`)}}
          </button>
        </b-col>
      </b-row>
      <b-row class="form-group row" v-if="!closed">
        <b-col sm="12" md="10" offset-md="2">
            <p>{{$t('filter-component.filter_dates')}}</p>
          <b-row>
            <b-col sm="12" md="6" lg="3">
              <label for="from">{{$t('filter-component.from')}}</label>
              <datetime
                v-model="search_values.from"
                input-class="form-control"
                input-id="from"
                :phrases="{ok: 'OK', cancel: $t('filter-component.cancel_date')}"
                class="theme-dashboard"
              ></datetime>
            </b-col>
            <b-col sm="12" md="6" lg="3">
              <label for="to">{{$t('filter-component.to')}}</label>
              <datetime
                v-model="search_values.to"
                input-class="form-control"
                input-id="to"
                :phrases="{ok: 'OK', cancel: $t('filter-component.cancel_date')}"
                class="theme-dashboard"
              ></datetime>
            </b-col>
            <b-col sm="12" lg="4">
              <button
                class="btn button-secondary filter filter-options"
                id="search"
                :class="filtered ? 'filtered' : ''"
                :disabled="empty || !valid"
                @click="setSearchDate('exact')"
              >
                {{$t('filter-component.filter')}}
                <i class="fas fa-search"></i>
              </button>
                <button
                class="btn btn-outline-danger clear"
                :disabled="!filtered"
                 @click="setSearchDate('all')"
              >
                {{$t('filter-component.clear')}}
              </button>
            </b-col>
            <b-col sm="9">
              <b-alert
                v-if="!valid && !empty"
                class="error-message text-center validation"
                show
                variant="danger"
              >{{$t('filter-component.invalid')}}</b-alert>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
</template>
<script>
import moment from 'moment';
import { Settings } from 'luxon';
import MatomoMixin from '../common/matomoMixin';

export default {
  mixins: [MatomoMixin],
  props: {
    language: {
      required: false,
    },
    searchFor: {
      required: false,
    },
    toggleButtonText: {
      required: false,
    },
    defaultFrom: {
      required: false,
    },
    defaultTo: {
      required: false,
    },
  },
  data() {
    return {
      search_values: {
        from: '',
        to: '',
      },
      rangeOptions: ['all', 'today', 'week', 'month'],
      closed: true,
      filtered: false,
    };
  },
  computed: {
    valid() {
      return this.search_values.from <= this.search_values.to;
    },
    empty() {
      return this.search_values.from === '' || this.search_values.from === null || this.search_values.to === '' || this.search_values.to === null;
    },
  },
  methods: {

    setSearchDate(searchType) {
      let from = (searchType === 'exact') ? this.search_values.from : new Date().toISOString();
      let to = (searchType === 'exact') ? this.search_values.to : new Date().toISOString();

      const utcFrom = moment(from).utc();
      from = moment(utcFrom).local();
      const utcTo = moment(to).utc();
      to = moment(utcTo).local();
      switch (true) {
        case searchType === 'today':
          from.set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          });
          break;
        case searchType === 'week':
          from.subtract(7, 'days');
          from.set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          });
          break;
        case searchType === 'month':
          from.subtract(1, 'months');
          from.set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          });
          break;
        case searchType === 'exact':
          from.set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          });
          to.set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          });
          to.add(1, 'days');
          break;
        case searchType === 'all':
          this.filtered = false;
          from = null;
          to = null;
          break;
        default:
          from = null;
          to = null;
      }
      from = from ? from.utc().toISOString() : null;
      to = to ? to.utc().toISOString() : null;
      if (searchType !== 'exact') {
        this.search_values.from = from;
        this.search_values.to = to;
      }
      this.filtered = true;
      this.$emit('on-search-by-dates', from, to);
      this.trackMatomoEvent(this.searchFor, 'Filter by', `date (${searchType})`);
    },
  },
  mounted() {
    Settings.defaultLocale = this.language;
    // fill the input with previously saved dates
    if (this.defaultFrom && this.defaultTo) {
      this.closed = false;
      this.filtered = true;
      // workaround. wihtout this from date in input is displayed 1 day minus (as the saved date is in UTC)
      setTimeout(() => {
        this.search_values.from = this.defaultFrom;
        this.search_values.to = this.defaultTo;
      }, 100);
    }
  },
};
</script>
<style scoped>
.search-button,
.search-button:hover:enabled,
.search-button:focus {
  background-color: rgb(255, 255, 255);
  color: #4e73df;
  border-color: #4e73df;
  border-radius: 4px;
  margin-top: 8px;
}
.search-button:hover:enabled,
.search-button:focus,
.search-button:active,
.filtered {
  background-color: #4e73df;
  color: white;
  font-weight: bold;
}

.filter {
  margin-right: 8px;
  margin-left: 16px;
  margin-top: 32px;
}

.clear {
  margin: 32px 8px 16px 16px;
}

.clear:hover:enabled {
  cursor: pointer;
}

.filter-options {
  margin-bottom: 16px;
}
#from {
  margin-bottom: 16px;
}
.filter {
  margin-right: 8px;
}
.expanded {
  transform: rotate(180deg);
}
.theme-dashboard .vdatetime-popup__header,
.theme-dashboard .vdatetime-calendar__month__day--selected > span > span,
.theme-dashboard .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #4e73df;
}

.theme-dashboard .vdatetime-year-picker__item--selected,
.theme-dashboard .vdatetime-time-picker__item--selected,
.theme-dashboard .vdatetime-popup__actions__button {
  color: #4e73df;
  font-weight: bold;
}
</style>
