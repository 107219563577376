<template>
  <i
    :class="iconClasses"
    class="sort-button"
    @click="sortBy()"
    :disabled="disabled"
  ></i>
</template>
<script>
export default {
  props: {
    value: {
      required: false,
    },
    sortOptions: {
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    iconClasses() {
      let classes = this.disabled ? 'sort-disabled ' : '';
      if (this.sortOptions.sorted_by == this.value) {
        classes = this.sortOptions.ascending ? `${classes}fa fa-sort-up` : `${classes}fa fa-sort-down`;
      } else {
        classes = `${classes}fa fa-sort`;
      }
      return classes;
    },
  },
  methods: {
    sortBy() {
      if (!this.disabled) {
        this.$emit('sort-by', this.value);
      }
    },
  },
};
</script>
<style scoped>
.sort-button{
  display: block;
  float: left;
  margin-right:4px;
}

.sort-disabled {
  cursor: not-allowed;
}

</style>
