<template>
  <div
    id="app"
    v-cloak
  >
    <!-- Loading indicator -->
    <loading
      v-if="userLoading"
    ></loading>

    <!-- Page Wrapper -->
    <div
      v-else-if="!reloadNeeded"
      id="wrapper"
    >
      <!-- Sidebar -->
      <side-nav
        v-if="clientTree"
        :client="client"
        :clientTree="clientTree"
        :sidebarToggled="sidebarToggled"
        @toggle-sidebar="onToggleSidebar()"
        @scroll-to-top="scrollToTop()"
      ></side-nav>

      <!-- Content Wrapper -->
      <div
        id="content-wrapper"
        class="d-flex flex-column"
      >
        <!-- Main Content -->
        <div :id="$route.name === 'Home' || $route.name === 'Aggregator' ?  'content' : ''">
          <!-- Topbar -->
          <navbar
            v-if="selectedClient !== null"
            :client="selectedClient"
            @toggle-sidebar="onToggleSidebar()"
          ></navbar>

          <navigation
            v-if="$route.name === 'Dashboard' ?
              selectedClient : selectedClient && selectedAssessment"
            :index_of_clients="index_of_clients"
            :clientTree="clientTree"
            :selectedAssessment="selectedAssessment"
          ></navigation>

          <!-- Begin Page Content -->
          <div class="container-fluid">
            <!-- Content Row -->
            <div class="row">
              <!-- Router View -->
              <error
                v-show="error"
              ></error>
              <b-container
                v-if="$route.name === 'Home'"
                fluid
              >
                <loading-inline />
              </b-container>
              <router-view
                :key="$route.fullPath"
                :loading="userLoading"
                :statisticsReady="statistics_ready"
              ></router-view>
            </div>
          </div>
          <!-- /.container-fluid -->
        </div>
        <!-- End of Main Content -->
        <!-- Footer -->
        <footer class="footer d-sm-none sticky-footer bg-white">
          <div class="container my-auto">
            <div class="text-right d-inline d-sm-none">
              <img
                v-if="selectedClient !== null"
                class="company-logo"
                :src="selectedClient.logo_url"
                alt
              />
            </div>

            <div class="copyright text-center my-auto"></div>
            <div class="text-left d-inline d-sm-none">
              <img
                class="benchmark-logo"
                src=/img/benchmark_logo.png
              >
            </div>
          </div>
        </footer>
        <!-- End of Footer -->
      </div>
      <!-- End of Content Wrapper -->
    </div>
    <page-reload
      v-else
      :countDown="countDown"
      @reload="reloadPage"
    ></page-reload>
    <!-- End of Page Wrapper -->

    <!-- Scroll to Top Button-->
    <a
      v-if="showScrollToTop"
      class="scroll-to-top rounded"
      @click="scrollToTop()"
    >
      <i class="fas fa-angle-up"></i>
    </a>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Navbar from './components/Navbar.vue';
import SideNav from './components/SideNav.vue';
import Navigation from './components/Navigation.vue';
import Loading from './components/Loading.vue';
import LoadingInline from './components/LoadingInline.vue';
import PageReload from './components/PageReload.vue';
import Error from './components/Error.vue';
import api from './common/api';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
  components: {
    Navbar,
    SideNav,
    Navigation,
    Loading,
    LoadingInline,
    PageReload,
    Error,
  },
  data() {
    return {
      clientSelected: false,
      showScrollToTop: false,
      sidebarToggled: false,
      reloadNeeded: false,
      interval: null,
      countDown: 5,
      idSet: false,
    };
  },
  computed: {
    ...mapState('dashboard', [
      'client',
      'user',
      'clientTree',
      'index_of_clients',
      'statistics_ready',
      'error',
    ]),
    ...mapState('select', [
      'selectedClient',
      'selectedAssessment',
    ]),
    ...mapState('user', ['userLoading', 'userinfo', 'userData']),
  },
  methods: {
    setFavicon() {
      const settingsData = JSON.parse(localStorage.getItem("settings"));
      const environment = settingsData?.environment;

      if (environment === "production") {
        document.querySelector("#apple-icon").href = "/favicons/apple-touch-icon.png";
        document.querySelector("#app-favicon-32").href = "/favicons/favicon-32x32.png";
        document.querySelector("#app-favicon-16").href = "/favicons/favicon-16x16.png";
        document.querySelector("#webmanifest").href = "/favicons/site.webmanifest";
      } else if (environment === "staging") {
        document.querySelector("#apple-icon").href = "/favicons/apple-touch-icon-staging.png";
        document.querySelector("#app-favicon-32").href = "/favicons/favicon-32x32-staging.png";
        document.querySelector("#app-favicon-16").href = "/favicons/favicon-16x16-staging.png";
        document.querySelector("#webmanifest").href = "/favicons/site-staging.webmanifest";
      } else {
        document.querySelector("#app-favicon-32").href = "/favicons/favicon-32x32-dev.png";
        document.querySelector("#app-favicon-16").href = "/favicons/favicon-16x16-dev.png";
      }
    },
    reloadPage() {
      window.location.reload();
    },
    ...mapActions('dashboard', ['getClient', 'getUser', 'getPlayers']),
    ...mapActions('select', ['setSelectedClient']),
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },

    onToggleSidebar() {
      this.sidebarToggled = !this.sidebarToggled;
    },

    navigate() {
      let client = null;

      if (this.$route.name === 'Home') {
        if (!this.client.length || this.client.length < 1) {
          this.getClient().then((result) => {
            const keys = Object.keys(this.clientTree);
            client = this.$route.params.clientId
              ? Number(this.$route.params.clientId)
              : this.clientTree[keys[0]].id;
            this.setSelectedClient(client);
            this.$router.push(`/client/${client}`);
          });
        }
      } else {
        // this is a workaround for scope renewal when one of the request got 403
        // eslint-disable-next-line no-lonely-if
        if (window.localStorage.getItem('reload_page')) {
          window.localStorage.removeItem('reload_page');
          window.location.reload();
        } else {
          // eslint-disable-next-line no-useless-return
          return;
        }
      }
    },

    handleScroll(event) {
      const scrollDistance = window.scrollY;
      if (scrollDistance > 100) {
        this.showScrollToTop = true;
      } else {
        this.showScrollToTop = false;
      }
    },
    // Get the info about new version of code, refresh page if new version available
    handleCodeChange() {
      api
        .fetchVersionTxt()
        .then((result) => {
          const previous = localStorage.getItem('version.txt');
          // convert response to string (because localStorage stores data as string)
          const current = result.data.toString();
          if (!previous) {
            localStorage.setItem('version.txt', current);
          }
          if (previous && previous !== current) {
            this.reloadNeeded = true;
            localStorage.setItem('version.txt', current);
            const self = this;
            setInterval(() => {
              self.countDown -= 1;
              if (self.countDown === 0) {
                self.reloadPage();
                clearImmediate(self.interval);
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.error('Could not load version.txt', err.data);
        });
    },
  },
  watch: {
    userData(newValue, oldValue) {
      if (newValue) {
        if (window._paq && newValue.clients && newValue.clients.length > 0) {
          // Set Visit Custom Dimension 1 "Client ID"
          window._paq.push(['setCustomDimension', 1, newValue.clients[0].id]);
          window._paq.push(['setCustomDimension', 3, newValue.clients[0].top_parent.id]);
        } else if (window._paq) {
          console.warn('Matomo - No "Client ID"');
        } else {
          console.warn('Matomo - No instance');
        }
      }
    },

    selectedClient(newValue, oldValue) {
      if (newValue) {
        if (window._paq && newValue.id) {
          // Set Action Custom Dimension 2 "Selected Client ID"
          window._paq.push(['setCustomDimension', 2, newValue.id]);
        } else if (window._paq) {
          console.warn('Matomo - No "Selected Client ID"');
        } else {
          console.warn('Matomo - No instance');
        }
      }
    },
  },

  beforeMount() {
    // Sidebar is toggled on mobile screens and small laptops (<992 px width - Bootstrap medium and below)
    if (window && window.innerWidth < 992) {
      this.sidebarToggled = true;
    }
  },

  mounted() {
    this.setFavicon();
    if (localStorage.getItem('access_token') && localStorage.getItem('settings')) {
      if (process.env.NODE_ENV !== 'development') {
        const self = this;
        setInterval(self.handleCodeChange, 30000);
      }
      this.navigate();
    }

    // Add scroll listener
    window.addEventListener('scroll', this.handleScroll);
  },

  updated() {
    if (window._paq && this.$store.state.user.userinfo && !this.idSet) {
      let id = this.$store.state.user.userinfo.email;
      window._paq.push(['setUserId', id]);
      this.idSet = true;
    }
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss">
html,
body {
  background-color: #f8f9fc;
  width: 100%;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  .footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
  }
}

h1,
h2 {
  font-weight: normal;
}

.scroll-to-top {
  display: block;
}
.scroll-to-top.rounded {
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .scroll-to-top.rounded {
    bottom: 76px;
  }
  #content {
    margin-top: 5.375rem;
    margin-bottom: 5.375rem;
  }
  .table th,
  .table td {
    // padding: 0.2rem;
    // line-height: 1.2;
    vertical-align: middle;
  }
  .table th,
  .table td button {
    line-height: 1.2;
    padding: 6px;
  }
}

.benchmark-logo,
.company-logo {
  position: absolute;
  transform: translate(0, -50%);
  height: auto;
  width: auto;
  max-height: 60px;
  max-width: 100px;
}

.benchmark-logo {
  right: 16px;
}

button.btn-primary,
button.btn-primary:hover,
button.btn-primary.active,
button.btn-primary:focus,
button.btn-primary.focus {
  background-color: #4e73df;
  border: 1px solid #4e73df !important;
}

.button-secondary {
  background-color: rgb(255, 255, 255);
  color: #4e73df;
  border: 1px solid #4e73df;
  border-radius: 4px;
}
.button-secondary:hover,
.button-secondary:focus,
.button-secondary.focus,
.button-secondary:active {
  background-color: #4e73df !important;
  color: white;
  border: 1px solid #4e73df !important;
}
.blue-text {
  color: #007bff;
}

.completeness-ok {
  border-left: 0.35rem solid #01b8aa;
}
.completeness-warning {
  border-left: 0.35rem solid #f2c80f;
}
.completeness-in-progress-finished {
  border-left: 0.35rem solid #8710ff;
}
.completeness-in-progress {
  border-left: 0.35rem solid rgba(95, 135, 255, 1);
}
.completeness-error {
  border-left: 0.35rem solid #ed5464;
}

.completeness-unused {
 border-left: 0.35rem solid rgba(233, 239, 253, 1);
}

.completeness-video-only {
  border-left: 0.35rem solid #858796;
}

.completeness-empty {
  border-left: 0.35rem solid rgba(196, 209, 255, 1);
}

.completeness-not-ready{
  border-left: 0.35rem solid #c183ff;
}
</style>
