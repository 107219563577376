<template>
  <div>
    <img
      class="game-icon"
      :id="`game${$vnode.key}`"
      :src="'/img/'+imageFileName"
      :alt="gameName.charAt(0).toUpperCase() + gameName.slice(1)"
    >
    <b-tooltip v-if="$t(`measuredCompetencies.${identifier}`) !== `measuredCompetencies.${identifier}`"
      :target="`game${$vnode.key}`"
      triggers="hover">
      <!-- list measured competencies in tooltip in detailed view -->
      <div v-if="detailed && $t(`measuredCompetencies.${identifier}`) !== `measuredCompetencies.${identifier}`">
        <div class="text-left">{{$t('measured_competencies')}}:</div>
        <ol v-if="typeof $t(`measuredCompetencies.${identifier}`) === 'object'" class="text-left">
          <li v-for="competence in Object.values($t(`measuredCompetencies.${identifier}`))"  :key="`game${$vnode.key}-${competence}`">
            {{ competence }}</li>
        </ol>
        <p v-else class="text-left">
          {{$t(`measuredCompetencies.${identifier}`)}}
        </p>
      </div>
      <!-- show game name in tooltip in compact view -->
      <div v-else>
        {{ gameName }}
      </div>
    </b-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    imageFileName: {
      required: true,
    },
    gameName: {
      required: true,
    },
    identifier: {
      required: true,
    },
    detailed: {
      required: true,
    },
  },
};
</script>
<style scoped>
.image-container {
  margin-bottom: 6px;
}
.game-icon {
  height: 30px;
  margin:4px;
}
</style>
