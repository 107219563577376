<template>
  <div>
    <div class="donut-chart-wrapper">
      <DoughnutChart :chartData="chartData" :options="options" :styles="chartStyles" />
      <div class="value">
        <span>{{ $t('dashboard.statistics_tab.donutchart_title') }}:</span>
        <div>{{ Math.round(finished) || 0 }}%</div>
      </div>
    </div>
    <slot name="legend">
      <div class="legend">
        <span
          class="legend-item"
          v-for="(item, idx) in dataCategories"
          :key="idx"
          :title="dataCategories[idx].labelTooltip"
        >
          <span
            class="legend-item-color"
            :style="{
              'background-color': dataCategories[idx].color,
              border: '1px solid rgba(133, 135, 150, 1)',
            }"
          ></span>
          <span>{{ item.labelText }}: {{ item.value }}</span>
        </span>
      </div>
    </slot>
  </div>
</template>
<script>
import DoughnutChart from './chartjs/doughnut-chart';

export default {
  components: {
    DoughnutChart,
  },
  data() {
    return {
      options: {
        cutoutPercentage: 70,
        legend: {
          display: false,
          position: 'bottom',
          fullWidth: true,
          labels: {
            boxWidth: 18,
          },
        },
        tooltips: {
          enabled: true,
          titleFontColor: 'black',
          mode: 'nearest',
          backgroundColor: '#eee',
          bodyFontColor: 'black',
          borderWidth: 1,
          borderColor: '#666666',
          // Prevent items with undefined labels from showing tooltips
          filter: (item, chart) => chart.labels[item.index] !== undefined,
        },
      },
      chartStyles: {
        height: 'auto',
        width: '250px',
        display: 'flex',
        'align-items': 'center',
        margin: 'auto',
        'z-index': 1,
      },
    };
  },
  props: {
    statistics: {
      required: true,
    },
    showInviteOnlyStats: {
      required: true,
    },
  },
  computed: {
    dataCategories() {
      if (this.statistics) {
        const values = [
          {
            value: this.statistics.finished_detailed.report_ok,
            labelText: this.$t('dashboard.statistics_tab.legend.completed'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.completed_desc'),
            color: '#01b8aa',
          },
          {
            value: this.statistics.finished_detailed.report_warning,
            labelText: this.$t('dashboard.statistics_tab.legend.incomplete_result'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.incomplete_result_desc'),
            color: '#f2c80f',
          },
          {
            value: this.statistics.finished_detailed.report_error,
            labelText: this.$t('dashboard.statistics_tab.legend.quit_after_onboarding'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.quit_after_onboarding_desc'),
            color: '#f87979',
          },
          {
            value: this.statistics.in_progress_finished_gameplays,
            labelText: this.$t('dashboard.statistics_tab.legend.in_progress_finished'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.in_progress_finished_desc'),
            color: '#a245ff',
          },
          {
            value: this.statistics.in_progress_gameplays,
            labelText: this.$t('dashboard.statistics_tab.legend.in_progress'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.in_progress_desc'),
            color: 'rgba(95, 135, 255,1)',
          },
          {
            value: this.statistics.not_started_gameplays,
            labelText: this.$t('dashboard.statistics_tab.legend.empty_attempt'),
            labelTooltip: this.$t('dashboard.statistics_tab.legend.empty_attempt_desc'),
            color: 'rgba(196, 209, 255, 1)',
          },
        ];

        const unusedCategory = {
          value: this.statistics.unused,
          labelText: this.$t('dashboard.statistics_tab.legend.unused_invitation'),
          labelTooltip: this.$t('dashboard.statistics_tab.legend.unused_invitation_desc'),
          color: 'rgba(233, 239, 253, 1)',
        };

        const notReadyCategory = {
          value: this.statistics.finished_detailed.report_not_ready,
          labelText: this.$t('dashboard.statistics_tab.legend.not_ready'),
          labelTooltip: this.$t('dashboard.statistics_tab.legend.not_ready_desc'),
          color: '#a245ff',
        };

        // show unused invitations category only if the assessment is invite only
        if (this.showInviteOnlyStats) {
          values.push(unusedCategory);
        }

        // add not ready category only if there is data in it
        if (this.statistics.finished_detailed.report_not_ready > 0) {
          values.push(notReadyCategory);
        }

        return values;
      }
      return null;
    },
    total() {
      return this.statistics.all_gameplays || 100;
    },
    finished() {
      return Math.round((this.statistics.finished_gameplays / this.statistics.all_gameplays) * 100);
    },
    chartData() {
      let items = null;
      let labels = [];
      if (this.dataCategories) {
        items = this.dataCategories;
        labels = items.map((item) => item.labelText);

        const datasets = [
          {
            data: items.map((item) => item.value),
            backgroundColor: items.map((item) => item.color),
            borderWidth: 0,
          },
        ];

        if (datasets[0].data.every((el) => el === 0)) {
          datasets[0].backgroundColor.push('rgb(238, 238, 238)');
          datasets[0].data.push(1);
        }
        return {
          datasets,
          labels,
        };
      }
      return null;
    },
  },
};
</script>
<style scoped>
.value {
  display: flex;
  text-align: cetner;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  align-items: center;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-size: 20px;
}

.value span {
  width: 85%;
}

.donut-chart-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
}

/* Legend container */
.legend {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  flex-wrap: wrap;
}

/* Legend item */
.legend-item {
  display: inline-flex;
  align-items: center;
  margin: 0.5em;
}

/* Color square inside each legend item */
.legend-item-color {
  height: 1.25em;
  width: 1.25em;
  border-radius: 2px;
  margin-right: 0.5em;
}
</style>
