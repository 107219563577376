import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { dashboardState, dashboardActions, dashboardMutations } from './dashboard-vuex';
import { selectState, selectActions, selectMutations } from './select-vuex';
import { userState, userActions, userMutations } from './user-vuex';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    user: {
      namespaced: true,
      state: userState,
      mutations: userMutations,
      actions: userActions,
    },
    dashboard: {
      namespaced: true,
      state: dashboardState,
      mutations: dashboardMutations,
      actions: dashboardActions,
      preserveState: true,
    },
    select: {
      namespaced: true,
      state: selectState,
      mutations: selectMutations,
      actions: selectActions,
    },
  },
  plugins: [createPersistedState({
    paths: [
      'select.selectedAssessment',
    ],
  })],
});
