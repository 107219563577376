import listToTree from 'list-to-tree-lite';
import moment from 'moment';
import api from './api';
import store from './store';

export const dashboardState = {
  client: [],
  user: [],
  userinfo: [],
  clientTree: null,
  assessments: null,
  index_of_clients: {},
  search_interval: {
    from: null,
    to: null,
  },
  statistics_ready: false,
  error: false,
};

export const dashboardMutations = {
  setUserinfo(state, data) {
    state.userinfo = data;
  },
  setClient(state, data) {
    state.client = data.items;
    for (let i = 0; i < state.client.length; i++) {
      const currentItem = state.client[i];
      const parentId = (currentItem.parent && currentItem.parent.id !== currentItem.id) ? currentItem.parent.id : null;
      let match = false;
      currentItem.parent = parentId;
      for (let j = 0; j < state.client.length; j++) {
        if (parentId === state.client[j].id) {
          match = true;
        }
      }
      if (match == false) {
        state.client[i].parent = null;
      }
    }
    state.clientTree = listToTree(state.client);

    // convert client tree into dictionary object format
    function keyByIdReducer(acc, client) {
      return { ...acc, [client.id]: client };
    }

    const new_tree = state.clientTree.reduce(keyByIdReducer, {});

    for (const key in new_tree) {
      new_tree[key].children = new_tree[key].children.reduce(keyByIdReducer, {});
      for (const child in new_tree[key].children) {
        new_tree[key].children[child].children = new_tree[key].children[child].children.reduce(keyByIdReducer, {});
      }
    }
    state.clientTree = new_tree;
    store.commit('dashboard/setIndexOfClients');
  },
  setIndexOfClients(state) {
    for (let i = 0; i < state.client.length; i++) {
      const current = state.client[i];
      let parent = null;
      let grandparent = null;
      state.index_of_clients[current.id] = {};
      if (current.parent === null) {
        state.index_of_clients[current.id].level = 1;
        state.index_of_clients[current.id].first_level_id = current.id;
        state.index_of_clients[current.id].second_level_id = null;
        state.index_of_clients[current.id].third_level_id = null;
      } else if (current.parent !== null) {
        parent = state.client.find((element) => element.id === current.parent);
        if (parent.parent) {
          grandparent = state.client.find(
            (element) => element.id === parent.parent,
          );
          state.index_of_clients[current.id].level = 3;
          state.index_of_clients[current.id].first_level_id = grandparent.id;
          state.index_of_clients[current.id].second_level_id = parent.id;
          state.index_of_clients[current.id].third_level_id = current.id;
        } else {
          state.index_of_clients[current.id].level = 2;
          state.index_of_clients[current.id].first_level_id = parent.id;
          state.index_of_clients[current.id].second_level_id = current.id;
          state.index_of_clients[current.id].third_level_id = null;
        }
      }
    }
  },

  setStatistics(state, params) {
    const current_clientId = params.args.params.clientId;
    const topclient_id = state.index_of_clients[current_clientId].first_level_id;

    const topClient = state.clientTree[topclient_id];

    topClient.statistics = params.data;
    if (Object.keys(topClient.children).length > 0) {
      for (const child in topClient.children) {
        topClient.children[child].statistics = topClient.statistics.subclients[child];
        if (Object.keys(topClient.children[child].children).length > 0) {
          for (const grandchild in topClient.children[child].children) {
            topClient.children[child].children[grandchild].statistics = topClient.statistics.subclients[grandchild];
          }
        }
      }
    }
    store.commit('dashboard/setStatsReady', true);
  },
  updateFrom(state, value) {
    state.search_interval.from = value;
  },
  updateTo(state, value) {
    state.search_interval.to = value;
  },

  setAssessments(state, data) {
    state.assessments = data;
  },

  clearAssessments(state) {
    state.assessments = null;
  },

  setStatsReady(state, value) {
    state.statistics_ready = value;
  },
  setError(state) {
    state.error = true;
  },
};

export const dashboardActions = {
  getClient({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.getClient(params).then((result) => {
        resolve(commit('setClient', result.data));
      });
    });
  },

  getStatistics({ commit }, params) {
    return new Promise((resolve, reject) => {
      const current = params.clientId;

      // the current client's top ancestor's statistics will be requested
      const topclient_id = store.state.dashboard.index_of_clients[current].first_level_id;

      params.clientId = topclient_id;
      commit('setStatsReady', false);
      api.getStatistics(params).then((result) => {
        resolve(commit('setStatistics', { args: { params }, data: result.data }));
      });
    });
  },
};
