<template>
  <gameplay-table
    v-if="data_ready && assessmentReady"
    :data_ready="data_ready"
    :candidates="reports"
    :selected_object="selectedAssessment"
    :allowCSV="allowCSV"
    :object_id="aggregatorId"
    :object_name="selectedAssessment.name"
    :anonym_project="isAnonymProject"
    :gamelinks="gamelinks"
    :custom_fields="custom_fields"
    :object_type="'aggregator'"
    :competence_headers_data="competence_headers"
    :profile_match_headers="profile_match_headers"
    :pageSize="pageSize"
    :pageCount="pageCount"
    :perPage="perPage"
    :total="total"
    :requestParams="requestParams"
    :table_updating="table_updating"
    @setPagesize="setPageSize"
    @setPages="setPages"
    @setFilter="setFilter"
    @setSortOptions="setSortOptions"
    ref="candidatesList"
  ></gameplay-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import api from '../common/api';

import GameplayTable from '../components/GameplayTable';

export default {
  components: {
    GameplayTable,
  },
  name: 'Aggregator',
  data() {
    return {
      aggregatorId: Number(this.$route.params.aggregatorId),
      candidates: [],
      reports: [],
      data_ready: false,
      table_updating: false,
      competence_headers: null,
      custom_fields: null,
      profile_match_headers: [],
      pageSize: null,
      pageCount: null,
      perPage: null,
      page: null,
      total: null,
      requestParams: {},
    };
  },
  computed: {
    ...mapState('dashboard', ['client']),
    ...mapState('select', ['selectedAssessment']),
    ...mapState('user', ['user_lang']),

    gamelinks() {
      return (this.selectedAssessment.slug_links && this.selectedAssessment.slug_links.length > 0) ? this.selectedAssessment.slug_links : [this.selectedAssessment.link];
    },
    isAnonymProject() {
      if (this.selectedAssessment.attributes) {
        return this.selectedAssessment.attributes.includes('ANONYMOUS');
      }
      return false;
    },
    assessmentReady() {
      return (this.selectedAssessment
        && (this.selectedAssessment.id === Number(this.$route.params.aggregatorId)));
    },
    allowCSV() {
      if (this.assessmentReady && this.selectedAssessment) {
        const client = this.client.filter((c) => this.selectedAssessment.client.id === c.id);
        return client[0].allow_csv;
      }
    },
  },
  methods: {
    ...mapActions('dashboard', ['getClient']),
    ...mapActions('select', ['setSelectedClient', 'setSelectedAssessment']),

    setHeaders(report) {
      const headers = {};

      report.forEach((c) => {
        headers[c.kpi.identifier] = c.kpi.name;
      });

      this.competence_headers = headers;
    },

    setCustomFieldHeaders() {
      this.custom_fields = this.selectedAssessment.custom_fields;
    },

    getDefaultProfileMatchName(profile_match, index, length) {
      const translated_name = this.$t('gameplay-table.table-columns.profile_match');
      if (length > 1) {
        return profile_match.name ? profile_match.name : `${translated_name} ${index + 1}`;
      }

      return translated_name;
    },

    setReportsData() {
      this.reports = [];
      const players = this.candidates;
      for (let i = 0; i < players.length; i++) {
        const player = {
          name: '-',
          external_id: '-',
          email: '-',
          id: '-',
          completeness: null,
          reportState: null,
        };
        if (players[i].player !== null) {
          player.name = players[i].player.name || '-';
          player.external_id = players[i].player.external_id || '-';
          player.email = players[i].player.email || '-';
          player.id = players[i].player.uuid || '-';
        }
        player.completeness = players[i].completeness;
        if (players[i].videos && players[i].videos.length > 0) {
          player.videos = players[i].videos;
        }

        player.custom_fields = {};
        players[i].custom_fields.forEach((field) => {
          player.custom_fields[field.project_field.export_key] = field.value;
        });

        for (let j = 0; j < players[i].gameplays.length; j++) {
          const gameplay = players[i].gameplays[j];

          if (gameplay.latest_report) {
            // set profile match values
            if (gameplay.latest_report.profile_matches) {
              if (gameplay.latest_report.state !== 'error') {
                // player.profile_matches = gameplay.latest_report.profile_matches;
                player.profile_matches = gameplay.latest_report.profile_matches.reduce((acc, profile_match, i) => {
                  acc[profile_match.calculator.id] = profile_match.value || '';
                  return acc;
                }, {}) || '';
              } else {
                player.profile_matches = {};
              }
            }

            player.links = gameplay.latest_report.report_links || '-';

            player.competencies = {};
            gameplay.latest_report.predictions.forEach((comp) => {
              player.competencies[comp.kpi.identifier] = comp.value;
            });

            if (
              !this.competence_headers
              && gameplay.latest_report.predictions
              && gameplay.latest_report.predictions.length > 0
            ) {
              this.setHeaders(gameplay.latest_report.predictions);
            }

            player.report_warnings = gameplay.latest_report.user_warnings;
            player.reportState = gameplay.latest_report ? gameplay.latest_report.state : null;
          }

          player.start_time = gameplay.start_time;
          player.end_time = gameplay.end_time;
          player.gameplayId = gameplay.prefixed_uuid;
        }
        this.reports.push(player);
      }
    },

    getCurrentAggregator() {
      api
        .getAssessmentById(`A-${this.aggregatorId}`, this.user_lang)
        .then((assessmentResponse) => {
          const assessment = assessmentResponse.data;
          this.setSelectedAssessment(assessment);
          // Set profile match headers
          this.profile_match_headers = assessment.calculators;
          this.custom_fields = assessment.custom_fields;
          this.findClientByAggregator(assessment.client.id);
        });
    },
    findClientByAggregator(clientId) {
      const client = this.$store.state.dashboard.client.find((c) => c.id === clientId);
      this.setSelectedClient(client.id);
    },

    async getTableData() {
      this.table_updating = true;
      const { requestParams } = this;
      const candidates = await api.getAssessmentCandidates(`A-${this.aggregatorId}`, requestParams);
      this.candidates = candidates.data.items;
      // number of candidates per page
      this.pageSize = candidates.data.size;
      // number of pages
      this.pageCount = Math.ceil(candidates.data.total / candidates.data.size);
      this.perPage = candidates.data.size;
      this.total = candidates.data.total;
      this.setReportsData();
      this.data_ready = true;
      this.table_updating = false;
    },
    setPageSize(value) {
      this.requestParams.size = value;
      // navigate back to the first page
      this.$refs.candidatesList.setPage(1);
      this.getTableData();
    },
    setPages(value) {
      this.requestParams.page = value;
      this.getTableData();
    },
    setSortOptions(sortOptions) {
      const prefix = !sortOptions.ascending ? '-' : '';
      this.requestParams.order_by = `${prefix}${sortOptions.sorted_by}`;
      this.getTableData();
    },
    setFilter(filterOptions, completenessSet) {
      let filterParams = '';
      Object.keys(filterOptions).forEach((key, index) => {
        const last = index === Object.keys(filterOptions).length - 1 ? '' : ',';
        if (filterOptions[key] !== null) {
          filterParams = filterParams.concat(`"${key}":"${filterOptions[key]}"${last}`);
        } else if (filterOptions[key] === null) {
          filterParams = filterParams.concat(`"${key}":${filterOptions[key]}${last}`);
        }
      });
      this.requestParams.filter_by = `{${filterParams}}`;
      if (completenessSet) {
        this.setPages(1);
      } else {
        this.getTableData()
      }
    },
  },
  async mounted() {
    if (this.client.length === 0) {
      await this.getClient();
      this.getCurrentAggregator();
    } else {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      this.getCurrentAggregator();
    }
    if (this.selectedAssessment) {
      this.profile_match_headers = this.selectedAssessment.profiles || this.selectedAssessment.calculators;
    }

    await this.getTableData();
  },
  beforeRouteLeave(to, from, next) {
    this.setSelectedAssessment(null);
    next();
  },
};
</script>
