<template>
  <ul
    id="accordionSidebar"
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    :class="sidebarToggled ? 'toggled' : ''"
  >
    <hr class="sidebar-divider d-none d-md-block">

    <!-- Benchmark logo -->
    <div class="text-center d-none d-md-inline">
      <img class="logo" :src=setLogo>
    </div>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">

    <!-- List of clients --->
    <side-nav-item
      v-for="(client) in clientTree"
      :key="client.id"
      :client="client"
      :sidebarToggled="sidebarToggled"
      @select-client="selectClient($event)"
    ></side-nav-item>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">

    <!-- Sidebar Toggler (mobile only) -->
    <div class="text-center d-none d-md-inline">
      <button
        class="rounded-circle border-0"
        id="sidebarToggle"
        @click.prevent="toggleSidebar()"
      ></button>
    </div>
  </ul>
</template>
<script>

import { mapState } from 'vuex';
import SideNavItem from './SideNavItem';


export default {
  components: {
    SideNavItem,
  },
  props: {
    clientTree: {
      required: true,
    },
    sidebarToggled: {
      require: true,
    },
  },

  computed: {
    ...mapState('select', ['selectedClient']),
    setLogo() {
      const settingsData = JSON.parse(localStorage.getItem("settings"));
      const environment = settingsData?.environment;
      
      if (environment === "production") {
        return "/img/benchmark_logo_white.png";
      } else if (environment === "staging") {
        return "/img/benchmark-games-staging.png";
      } else {
        return "/img/benchmark-games-dev.png";
      }
    },
  },

  methods: {
    selectClient(client) {
      // Close menu on mobile
      if (window && window.innerWidth < 768) {
        this.toggleSidebar();
      }

      // Scroll to top
      this.$emit('scroll-to-top');
    },

    toggleSidebar() {
      this.$emit('toggle-sidebar');
      window.dispatchEvent(new Event('resize'));
    },
  },
};
</script>
<style lang="scss">

.sidebar {
  z-index: 2;
  flex: 0 0 auto;
  .logo {
    width: 90px;
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 767px) {
  .sidebar {
    min-height: unset;
    max-height:100%;
    top: 4.375rem;
    width: 100% !important;
    position: fixed;
    z-index: 2;
    overflow: auto;
  }
}

.sidebar .nav-item a.nav-link,
a.nav-link.collapsed {
  padding: 8px 0 8px 0;
  display: block !important;
}

.sidebar .nav-item:last-child {
  margin-bottom: 0px;
}

//Toggled sidebar

.sidebar.toggled .nav-item .nav-link[class="collapsed"]::after {
  display: inline-block;
  float: none;
}

@media (min-width: 768px) {
  .sidebar.toggled .nav-item:last-child {
    margin-bottom: 0px;
  }
}

a.nav-link.parent.child + .collapse.show {
  z-index: auto;
  position: static;
}
</style>
