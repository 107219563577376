<template>
  <div>
    <div>
      <recruitment-list-full
        v-if="assessments.length < 6"
        :assessments="assessments"
      ></recruitment-list-full>
      <recruitment-list-simple
        v-if="assessments.length > 5"
        :assessments="assessments"
      ></recruitment-list-simple>
    </div>
    <b-alert v-if="assessments.length < 1" show variant="primary" class="no-data">{{
      $t('dashboard.recruitment_list_tab.empty')
    }}</b-alert>
  </div>
</template>
<script>
import RecruitmentListSimple from './RecruitmentListSimple.vue';
import RecruitmentListFull from './RecruitmentListFull.vue';

export default {
  name: 'RecruitementListWrapper',

  components: {
    RecruitmentListSimple,
    RecruitmentListFull,
  },

  props: {
    assessments: {
      required: true,
    },
  },
};
</script>
