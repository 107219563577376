import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import store from './store';
import { RE_AUTHENTICATE } from './constants';
import { router } from './router';

/* -----------------AXIOS_MAIN_INSTANCE-----------------
 is used by the majority of get requestswith access token
*/

// setting rate limit for requests on this axios_main_instance
export const axios_main_instance = rateLimit(axios.create(), { maxRequests: 15, perMilliseconds: 1000 });

// REQUEST
axios_main_instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error({
      error,
    });
    return Promise.reject(error);
  },
);

// RESPONSE
axios_main_instance.interceptors.response.use(
  (response) => response,
  (error) => {
    switch (true) {
      case error.config && error.response && error.response.status === 400:
        router.push('/400');
        break;
      case error.config && error.response && error.response.status === 401:
        RE_AUTHENTICATE(error.response.status, error.response.data);
        break;
      case error.config && error.response && error.response.status === 403:
        RE_AUTHENTICATE(error.response.status, error.response.data);
        break;
      case error.config && error.response && error.response.status === 404:
        router.push('/404');
        break;
      case error.config && error.response && error.response.status === 409:
        router.push('/409');
        break;
      case error.config && error.response && error.response.status >= 500:
        router.push('/500');
        break;
      default:
        store.commit('dashboard/setError'); // display a general error message to the user
        break;
    }
    return Promise.reject(error);
  },
);

/* -----------------AXIOS_MEW_INSTANCE-----------------
 is currently used by the "logout" function
*/

export const axios_new_instance = axios.create();
axios_new_instance.interceptors.request.use(
  (response) => response,
  (error) => {
    console.error('unsuccessful logout');
    return Promise.reject(error);
  },
);

axios_new_instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  (error) => {
    console.error({
      error,
    });
    return Promise.reject(error);
  },
);

axios_new_instance.interceptors.response.use(
  (config) => config,
  (error) => {
    console.error({
      error,
    });
    return Promise.reject(error);
  },
);

/* ------------------AXIOS_NO_AUTH------------------
 this instance is used for request "settings.json",
 it requires no authentication
*/
export const axios_no_auth = axios.create();
axios_no_auth.interceptors.request.use(
  (config) => config,
  (error) => {
    store.commit('dashboard/setError');
    console.error({
      error,
    });
    return Promise.reject(error);
  },
);

axios_no_auth.interceptors.response.use(
  (config) => config,
  (error) => {
    switch (true) {
      case error.config && error.response && error.response.status === 400:
        router.push('/400');
        break;
      case error.config && error.response && error.response.status === 401:
        router.push('/403');
        RE_AUTHENTICATE(error.response.status, error.response.data);
        break;
      case error.config && error.response && error.response.status === 403:
        router.push('/403');
        RE_AUTHENTICATE(error.response.status, error.response.data);
        break;
      case error.config && error.response && error.response.status === 404:
        router.push('/404');
        break;
      case error.config && error.response && error.response.status === 409:
        router.push('/409');
        break;
      case error.config && error.response && error.response.status >= 500:
        router.push('/500');
        break;
      default:
        store.commit('dashboard/setError'); // display a general error message to the user
        break;
    }
    console.error({
      error,
    });
    return Promise.reject(error);
  },
);
