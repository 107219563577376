<template>
  <div class="gamelink-component" ref="container">
    <button class="btn button-secondary copy-link"
      v-clipboard:copy="gamelink"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
      >{{$t('gamelink-component.copy_button')}}</button>
    <div class="gamelink">
      <p>
        {{gamelink}}
        <span v-if="success" class="tooltiptext">{{ successMessage }}</span>
        <span v-if="error" class="tooltiptext">{{ errorMessage }}</span>
      </p>
    </div>
  </div>
</template>
<script>

import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

export default {
  props: {
    gamelink: {
      required: true,
    },
  },
  data() {
    return {
      successMessage: this.$t('gamelink-component.tooltip_text'),
      errorMessage: this.$t('gamelink-component.copy_error'),
      success: false,
      error: false,
    };
  },
  methods: {
    onCopy() {
      const { container } = this.$refs;
      this.$copyText(this.gamelink, container);
      this.success = true;
      setTimeout(() => { this.success = false; }, 1000);
    },
    onError() {
      this.error = true;
      setTimeout(() => { this.error = false; }, 1000);
    },
  },
};
</script>
<style scoped>
.gamelink-component {
  padding-left: 16px;
  margin-bottom: 16px;
}
.copy-link {
  margin-bottom: 16px;
}
.gamelink {
  margin-bottom: 10px;
}
.gamelink p {
  display: inline;
  color: #007bff;
  position: relative;
}
.gamelink p.link {
  color: #2c3e50;
  font-weight: bold;
}
.gamelink .tooltiptext {
  display: inline;
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  background-color: rgba(33, 37, 41, 0.9);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.gamelink .tooltiptext:after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
</style>
