<template>
  <b-row>
    <!-- Show more modal -->
    <b-modal
      id="assessment-show-more-modal"
      size="lg"
      v-model="showAssessmentDetailsModal"
      :title="selectedAssessment ? selectedAssessment.name : ''"
      hide-footer
    >
      <recruitment-list-full-item
        v-if="selectedAssessment"
        :item="selectedAssessment"
        :router_link_params="getRouterLinkParams(selectedAssessment)"
      ></recruitment-list-full-item>
    </b-modal>

    <!-- Invitation modal -->
    <invitation-modal-component
      v-if="showInvitationModal"
      :assessment="selectedAssessment"
      @closeModal="showInvitationModal = false"
    ></invitation-modal-component>

    <!-- List -->
    <b-col md="10" offset-md="1">
      <div class="table-responsive recruitment-table">
        <table class="table">
          <!-- table header -->
          <thead>
            <tr>
              <td class="header-column">{{ $t('dashboard.recruitment_list_tab.simple.name') }}</td>
              <td class="header-column">{{ $t('dashboard.recruitment_list_tab.simple.games') }}</td>
              <td class="header-column">
                {{ $t('dashboard.recruitment_list_tab.simple.nr_of_gameplays') }}
              </td>
              <td class="header-column">
                {{ $t('dashboard.recruitment_list_tab.simple.created_at') }}
              </td>
              <td class="header-column">
                {{ $t('dashboard.recruitment_list_tab.simple.show_more') }}
              </td>
              <td class="header-column">{{ $t('invitation.invite_candidates') }}</td>
            </tr>
          </thead>
          <tbody>
            <recruitment-list-simple-item
              v-for="assessment in assessments"
              :item="assessment"
              :key="assessment.id"
              :router_link_params="getRouterLinkParams(assessment)"
              @open-assessment-details-modal="openAssessmentDetailsModal"
              @open-invitation-modal="openInvitationModal"
            ></recruitment-list-simple-item>
          </tbody>
        </table>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import RecruitmentListSimpleItem from './RecruitmentListSimpleItem.vue';
import RecruitmentListFullItem from './RecruitmentListFullItem.vue';
import InvitationModalComponent from './InvitationModalComponent.vue';

export default {
  components: {
    RecruitmentListSimpleItem,
    RecruitmentListFullItem,
    InvitationModalComponent,
  },
  data() {
    return {
      showAssessmentDetailsModal: false,
      showInvitationModal: false,
    };
  },
  props: {
    assessments: {
      required: true,
    },
  },
  computed: {
    ...mapState('select', ['selectedAssessment']),
  },

  methods: {
    getRouterLinkParams(assessment) {
      const name = ((assessment.prefixed_id && assessment.prefixed_id.includes('A')) || assessment.members) ? 'Aggregator' : 'Project';
      const params = {};
      params[`${name.toLowerCase()}Id`] = assessment.id;
      return { name, params };
    },

    openAssessmentDetailsModal() {
      this.showAssessmentDetailsModal = true;
    },

    openInvitationModal(item) {
      this.setSelectedAssessment(item);
      this.showInvitationModal = true;
    },

    setDefault() {
      this.showAssessmentDetailsModal = false;
      this.showInvitationModal = false;
      this.setSelectedAssessment(null);
    },
    ...mapActions('select', ['setSelectedAssessment']),
  },
};
</script>
<style scoped>
.recruitment-table {
  margin: 16px !important;
}

.header-column {
  vertical-align: bottom !important;
}
.header-column:nth-child(3) {
  width: 150px;
}
</style>

<style lang="scss">
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.assessment-show-more-modal {
  padding-left: 0px;
}
</style>
