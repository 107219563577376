<template>
  <th
    scope="col"
    :class="{competence : column_type==='competence'}"
  >
    <div class="column-name-container">
      <sort-button
        :sortOptions="sortOptions"
        :value="identifier"
        :disabled="table_updating"
        @sort-by="$emit('setSortOptions', identifier)"
      ></sort-button>
      <div class="text-wrapper">
        {{ headerName }}
      </div>
      <div>
        <b-button
          :id="'popover'+index"
          class="btn overflow-button"
        >...</b-button>
        <b-popover
          :target="'popover'+index"
          placement="bottomleft"
          triggers="click"
          :content="headerName"
        ></b-popover>
      </div>
    </div>
  </th>
</template>
<script>
import SortButton from './SortButton.vue';

export default {
  components: {
    SortButton,
  },
  props: {
    headerName: {
      required: true,
    },
    sortOptions: {
      required: true,
    },
    column_type: {
      required: true,
    },
    index: {
      required: true,
    },
    identifier: {
      required: true,
    },
    table_updating: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    displayHelpicon() {
      Array.from(document.getElementsByClassName('text-wrapper')).forEach(
        (element) => {
          if (element.scrollWidth < 140) {
            element.nextElementSibling.style.display = 'none';
          }
        },
      );
    },
  },
  mounted() {
    this.displayHelpicon();
  },
};
</script>
<style scoped>
.column-name-container {
  display: flex;
  align-items: center;
}

.text-wrapper {
  max-width: 123px;
  height: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

button.text-wrapper {
  border: none;
  outline: none;
  background-color: white;
}
.btn.overflow-button,
.btn.overflow-button:hover,
.btn.overflow-button:active,
.btn.overflow-button:focus {
  padding: 0 3px 0 3px;
  margin-left: 3px;
  color: #858796;
  border: 1px solid #858796;
  background-color: white;
  outline: none;
  line-height: 1;
  display: inline-block;
  font-weight: bold;
  z-index: 888;
}

.competence {
  min-width: 180px !important;
}
</style>
