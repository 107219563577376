export const settings = (window.localStorage.getItem('settings')) ? JSON.parse(localStorage.getItem('settings')) : null;

const scopes = 'read:aggregator+read:player+read:game-report+write:client+read:project+read:user+email+read:client+read:game+write:project+read:game-play+read:assessment+write:assessment';
const auth_params = `?client_id=front-dashboard&response_type=token&scope=${scopes}&redirect_uri=${window.location.origin}/auth-handler`;

export const AUTH_PATH = (settings === null) ? null : settings.auth_url + auth_params;

export const SERVER_PATH = (settings === null) ? null : settings.api_url;
export const APIV2_PATH = (settings === null) ? null : settings.apiv2_url;
export const AS_API_PATH = (settings === null) ? null : settings.as_api_url;

// TODO: delete the below condition after account service is deployed to production
const logout_params = (settings && settings.logout_url && settings.logout_url.includes('accounts')) ? `?next=${window.location.origin}` : '?next=/';
export const LOGOUT_PATH = (settings === null) ? null : settings.logout_url + logout_params;

export const REPORT_PATH = (settings === null) ? null : settings.report_url;

export const GAMELINK_PATH = (settings === null) ? null : settings.game_url;

export const RE_AUTHENTICATE = function (responseStatus, responseData) {
  const reAuthenticatedAt = localStorage.getItem('ReAuthenticated');
  const diff = new Date().getTime() - reAuthenticatedAt;
  if (!reAuthenticatedAt || (reAuthenticatedAt && diff > 5000)) {
    localStorage.removeItem('access_token');
    // remove access token before renewing it
    window.localStorage.setItem('reload_page', true);
    localStorage.setItem('ReAuthenticated', new Date().getTime());
    let wait_ms = 0;
    if (window._paq) {
      window._paq.push(['trackEvent', 'ReAuthenticate', `Status=${responseStatus}`, `Data=${responseData ? JSON.stringify(responseData) : 'empty'}`]);
      wait_ms = 500;
    }
    window.setTimeout(() => {
      if (window.location.pathname !== '/auth-handler') {
        window.localStorage.setItem('path', window.location.pathname);
        const waitForPath = setInterval(() => {
          if (window.localStorage.getItem('path') && window.localStorage.getItem('path') === window.location.pathname) {
            location.href = AUTH_PATH;
            window.clearInterval(waitForPath);
          }
        }, 100);
      }
    }, wait_ms);
  }
};

const GAME_ICONS = {
  cc_mobile: 'curiocity',
  curiocity_short: 'curiocity',
  curiocity: 'curiocity',
  dotto_mobile: 'dotto',
  dotto_new_report: 'dotto',
  dotto: 'dotto',
  dotto2: 'dotto',
  multitask_hu_short: 'multitask',
  multitask_hu: 'multitask',
  multitask_tutorial: 'multitask',
  multitask: 'multitask',
  old_curiocity: 'curiocity',
  quiz: 'quiz',
  sortory: 'sortory',
};

export const getGameIcon = (identifier) => {
  if (GAME_ICONS.hasOwnProperty(identifier)) return GAME_ICONS[identifier];

  return 'default.png';
};
