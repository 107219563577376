<template>
  <b-col cols="12" md="6" offset-md="3" class="text-center">
    <div class="error mx-auto">500</div>
    <p class="lead text-gray-800 mb-5">{{$t('errors.500.title')}}</p>
    <p class="lead text-gray-800 mb-5" v-html="$t('errors.500.text')"></p>
     <a class="back" @click="refresh">&larr; {{$t('errors.back_to_homepage')}}</a>
  </b-col>
</template>
<script>
export default {
  methods: {
    refresh() {
      this.$router.push({ path: '/' });
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.email{
  font-weight: bold;
}
.back{
  color: #007bff !important;
  cursor:pointer;
}
</style>
