<template>
  <div id="csv-download">
    <nav class="navbar navbar-light bg-white topbar mb-4 static-top shadow">
      <div class="benchmark_logo">
        <img src="/img/benchmark_logo.png" alt="" />
      </div>
    </nav>
    <div class="text-content">
      <div>
        <h2>{{ $t(`csv_${downloadState}`) }}</h2>
        <p v-if="errorDetail">{{ errorDetail }}</p>
        <p class="error-detail-text">{{ $t('csv_direct_download_text')}}</p>
        <a :href="APIV2_PATH + csvUrl" class="back" target="_blank" id="download">{{ $t('download') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../common/api';
import MatomoMixin from '../common/matomoMixin';
import { APIV2_PATH } from '../common/constants';

export default {
  mixins: [MatomoMixin],
  data() {
    return {
      downloadState: 'downloading_soon',
      errorDetail: null,
      cvsData: null,
      APIV2_PATH,
    };
  },
  computed: {
    csvUrl() {
      return `assessments/csv/${this.$route.params.csvId}/?access_token=${localStorage.getItem('access_token')}`;
    },
  },
  methods: {
    downloadCSV() {
      this.downloadState = 'downloading';
      api.downloadCSV(this.csvUrl)
        .then((res) => {
          this.cvsData = res.data;
          // create file link in browser's memory
          const url = window.URL.createObjectURL(new Blob([res.data]));

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.$route.query.file_name || this.$route.params.csvId}.csv`);
          this.trackMatomoEvent('CSV report', 'downloaded', this.$route.params.csvId);
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(url);

          this.downloadState = 'saved';
        })
        .catch(async (err) => {
          this.downloadState = 'download_error';
          if (err.response && err.response.data) this.errorDetail = await err.response.data.text();
          this.trackMatomoEvent('CSV report', 'download error', this.errorDetail ? this.errorDetail : JSON.stringify(err));
          console.error(err);
        });
    },
  },
  mounted() {
    this.downloadCSV();
  },
};
</script>

<style scoped>
  #csv-download {
    background-image: url('../../public/img/benchmark_background.svg');
    position: fixed;
    left: 0px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .benchmark_logo {
  margin: auto;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  }
  .benchmark_logo img {
    width: 145px;
    max-height: 33px;
    vertical-align: middle;
  }

  .text-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    text-align: center;
    font-size: 18px;
  }

  #content {
    margin-top: 0 !important;
    margin-bottom: 0;
  }
</style>
